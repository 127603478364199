import { View, Text, StyleSheet, ScrollView } from "react-native";
import { useState, useEffect } from "react";
import { Typography, Button } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import { AddCircleOutlined, RemoveCircleOutline } from "@material-ui/icons";
import { OrderPart, LastOrders } from "../index";
import fetchConf from "../../fetchConfig";
const axios = require("axios");

export default function AgentsOrderParts({
  customer,
  company,
  setShowModal,
  setModalContent,
  setShowOverlayLoader,
  validateMainOrderData,
  handleSend,
}) {
  const [parts, setParts] = useState([]);
  const [partsRows, setPartsRows] = useState(null);
  const [partsOptions, setPartsOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [customerLastOrders, setCustomerLastOrders] = useState(null);
  const [openLastOrders, setOpenLastOrders] = useState(false);

  const handleAddPart = () => {
    if (validateMainOrderData()) setParts((prev) => [...prev, null]);
  };

  const handleRemovePart = (index) => {
    // console.log("index to remove: ", index);
    setParts((prev) => {
      const newPartsArray = [...prev];
      newPartsArray.splice(index, 1);
      return newPartsArray;
    });
  };

  const addValidPart = (index, partData) => {
    setParts((prev) => {
      const newPartsArray = [...prev];
      newPartsArray[index] = {
        ...partData,
        totalPrice:
          parseFloat(partData.agentPrice) * parseInt(partData.quantity),
        agentPrice: parseFloat(partData.agentPrice),
        quantity: parseFloat(partData.quantity),
      };
      return newPartsArray;
    });
  };

  const handleOrderSelected = (orderData) => {
    // console.log(orderData);
    const orderParts = orderData?.lines
      .filter((line) => line.checked)
      .map((line) => {
        return {
          id: line.PartID,
        };
      });

    for (let i = 0; i < orderParts.length; i++) {
      for (let partData of partsOptions) {
        if (orderParts[i].id == partData.id) {
          orderParts[i] = partData;
        }
      }
    }
    setParts(orderParts);
  };

  useEffect(() => {
    setParts([]);
    setCustomerLastOrders(null);
    if (customer) {
      console.log("Getting parts for selected customer...");
      setShowOverlayLoader(true);
      (async () => {
        try {
          const partsData = await axios.get(`${fetchConf}/getItems`, {
            params: {
              customerId: customer.CustomerID,
              migvan: customer.Migvan,
              compId: company,
            },
            timeout: 5000,
          });
          const resolvedPartsData = await partsData?.data;
          const options = resolvedPartsData?.map((item, index) => {
            return {
              key: index + 1,
              id: item.PartID,
              label: item.PartDes || item.GenerlDes,
              basePrice: item.NetPrice || item.BasePrice,
              base64Img: item.SigBase64,
              agentPrice: item.OrderPrice || item.NetPrice || item.BasePrice,
            };
          });
          setPartsOptions(options);
        } catch (error) {
          setModalContent({
            title: "שגיאה",
            content: "שגיאה במשיכת פריטים אפשריים ללקוח, אנא נסו שנית.",
          });
          setShowModal(true);
          console.log("Error fetching parts options...", error);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
      (async () => {
        try {
          const unitsData = await axios.get(`${fetchConf}/getUnits`);
          const resolvedUnits = await unitsData.data;
          setUnitOptions(
            resolvedUnits.map((item) => {
              return {
                key: item.UnitsID,
                label: item.UnitsDes,
              };
            })
          );
        } catch (error) {
          setModalContent({
            title: "שגיאה",
            content: "שגיאה במשיכת סוגי יחידות למוצרים, אנא נסו שנית.",
          });
          setShowModal(true);
          console.log("Error fetching units options...", error);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [customer]);

  useEffect(() => {
    setPartsRows(
      parts.map((part, index) => {
        return (
          <View style={styles.partComponent} key={index}>
            <OrderPart
              customer={customer}
              compId={company}
              addValidPart={addValidPart}
              index={index}
              part={part}
              setShowOverlayLoader={setShowOverlayLoader}
              partsOptions={partsOptions}
              unitOptions={unitOptions}
              setShowModal={setShowModal}
              setModalContent={setModalContent}
            />
            <IconButton onClick={() => handleRemovePart(index)}>
              <RemoveCircleOutline />
              <Text> הסר</Text>
            </IconButton>
          </View>
        );
      })
    );
  }, [parts]);

  const loadCustomerLastOrders = () => {
    if (validateMainOrderData()) {
      setShowOverlayLoader(true);
      (async () => {
        try {
          const ordersData = await axios.get(
            `${fetchConf}/getOrdersByCustomer`,
            {
              params: {
                customerID: customer.CustomerID,
              },
              timeout: 5000,
            }
          );
          const resolvedOrders = await ordersData.data;
          // console.log(resolvedOrders);
          setCustomerLastOrders(resolvedOrders);
        } catch (error) {
          console.log("Error fetching last orders of customer: ", error);
        } finally {
          setShowOverlayLoader(false);
          setOpenLastOrders(true);
        }
      })();
    }
  };
  // console.log(partsOptions);
  // console.log(parts);
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 16,
          flex: 1,
        }}
      >
        <Typography variant="h6">פריטים:</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={loadCustomerLastOrders}
        >
          הזמנות קודמות
        </Button>
      </View>
      {customerLastOrders && (
        <LastOrders
          openLastOrders={openLastOrders}
          setOpenLastOrders={setOpenLastOrders}
          customerLastOrders={customerLastOrders}
          handleOrderSelected={handleOrderSelected}
        />
      )}
      <ScrollView style={{ maxHeight: 200 }}>{partsRows}</ScrollView>
      <IconButton onClick={handleAddPart}>
        <AddCircleOutlined />
        <Text> הוסף פריט נוסף</Text>
      </IconButton>
      <View style={{ alignItems: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleSend(parts);
          }}
          style={{ width: "75%" }}
        >
          שלח הזמנה
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  partComponent: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
