import { View, Text, ScrollView } from "react-native";
import { useEffect, useState } from "react";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
} from "@material-ui/core";
import fetchConf from "../../fetchConfig";
import axios from "axios";

export default function PartReport({
  showPartReport,
  setShowPartReport,
  partId,
  customerId,
  setShowOverlayLoader,
  setShowModal,
  setModalContent,
}) {
  const [partReports, setPartReports] = useState([]);

  useEffect(() => {
    if (showPartReport) {
      //   console.log(partId, customerId);
      console.log("Getting part report of selected customer and part...");
      setShowOverlayLoader(true);
      (async () => {
        try {
          const partReportData = await axios.get(`${fetchConf}/getPartReport`, {
            params: {
              customerId: customerId,
              partId: partId,
            },
            timeout: 5000,
          });
          const resolvedPartReport = await partReportData?.data;
          setPartReports(resolvedPartReport);
        } catch (error) {
          setModalContent({
            title: "שגיאה",
            content: "שגיאה במשיכת דוח פריט, אנא נסו שנית.",
          });
          setShowModal(true);
          console.log("Error fetching part report...", error);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [showPartReport]);

  const partReportsList = partReports.map((part) => {
    return (
      <View
        key={part?.CustOrderID}
        style={{
          borderRadius: 4,
          borderWidth: 1,
          borderColor: "#ccc",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 4,
        }}
      >
        <Text>הזמנה {part?.CustOrderID}</Text>
        <Text>מתאריך {new Date(part?.TransDate).toLocaleDateString("he")}</Text>
        <Text>מחיר {part?.BasePrice}</Text>
        <Text>כמות {part?.Qunt}</Text>
      </View>
    );
  });
  // console.log(partReportsList);
  return (
    <Dialog open={showPartReport}>
      <DialogTitle>דו"ח פריט</DialogTitle>
      <DialogContent>
        {partReportsList?.length > 0 ? (
          <ScrollView style={{ maxHeight: 400 }}>{partReportsList}</ScrollView>
        ) : (
          <Text>לא נמצאו הזמנות של הלקוח הנבחר עם פריט זה...</Text>
        )}
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button variant="outlined" onClick={() => setShowPartReport(false)}>
          סגור
        </Button>
      </DialogActions>
    </Dialog>
  );
}
