import {
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";

export default function DeficienciesTable({ headers, rows }) {
  // orderData("2001112");
  return (
    <TableContainer>
      <Table
        size="small"
        style={{
          width: "100%",
          overflowX: "scroll",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <TableHead>
          <TableRow>
            {headers.map((header) => {
              return (
                <TableCell key={header} align="right">
                  {header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {row?.map((item, index2) => (
                <TableCell key={`${index}-${index2}`} align="right">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
