import * as React from "react";
import { StyleSheet, Text, View, Dimensions } from "react-native";
import ReactModal from "react-modal";
import Button from "@material-ui/core/Button";

export default function Modal({
  show,
  content,
  title,
  buttonText = "שגיאה",
  toggleShowModal,
  buttonFunction,
}) {
  const { height } = Dimensions.get("window");
  if (show) {
    return (
      <ReactModal
        appElement={document.getElementById("root")}
        isOpen={show}
        style={{
          content: {
            writingDirection: "rtl",
            height: height > 300 ? height * 0.6 : height * 0.9,
            textAlign: "center",
            zIndex: "5000",
            inset: height < 300 ? 5 : 40,
            padding: height < 300 ? 5 : 20,
          },
        }}
      >
        {
          <h4
            style={{
              marginTop: height < 300 ? 5 : 15,
              marginBottom: height < 300 ? 5 : 15,
            }}
          >
            {title}
          </h4>
        }
        <div
          style={{
            // height: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          {content}
        </div>
        <Button
          variant="contained"
          id="searchOrderBtn"
          color="primary"
          size="small"
          // style={{
          //   margin: "0 auto",
          //   width: "100%",
          //   bottom: "0",
          // }}
          onClick={() => {
            toggleShowModal(false);
            if (buttonFunction) buttonFunction();
            // setOrderViewd(true);
          }}
        >
          {buttonText}
        </Button>
      </ReactModal>
    );
  } else return null;
}
