import {
  View,
  Text,
  ScrollView,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { useState, useEffect } from "react";
import { faBarcode, faCheck } from "@fortawesome/free-solid-svg-icons";
import { BarcodeReader, BackToNavBtn, MyTable } from "../../components/index";
import { Button, Typography, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectList } from "react-native-dropdown-select-list";
import Dialog from "@mui/material/Dialog";
import COLORS from "../../constants/COLORS";
import fetchConf from "../../fetchConfig";
const axios = require("axios");

export default function OutStorageScan({
  setShowOutStorageScan,
  setShowButtons,
  showOutStorageScan,
  setShowOverlayLoader,
  Modal,
  user,
}) {
  const [modalContent, setModalContent] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showBarcodeComponent, setShowBarcodeComponent] = useState(false);
  const [scanValue, setScanValue] = useState(null);
  const [selectedOutStorage, setSelectedOutStorage] = useState(null);
  const [outStorageOptions, setOutStorageOptions] = useState([]);
  const [numberOfCartons, setNumberOfCartons] = useState("");
  const [platformData, setPlatformData] = useState(null);
  const [barcodeInput, setBarcodeInput] = useState("");
  const [tableRows, setTableRows] = useState(null);
  const [resetOrdersLines, setResetOrdersLines] = useState(false);

  // console.log({
  //   user,
  //   selectedOutStorage,
  //   scanValue,
  //   barcodeInput,
  //   platformData,
  //   numberOfCartons,
  // });
  useEffect(() => {
    if (showOutStorageScan && !outStorageOptions.length) {
      console.log("fetching out storages...");
      setShowOverlayLoader(true);
      (async () => {
        try {
          const outStoragesData = await axios.get(
            `${fetchConf}/getOutStorages`
          );
          const resolvedOutStorages = await outStoragesData?.data;
          //   console.log(resolvedOutStorages);
          const outStoragesOptions = resolvedOutStorages?.map((item) => {
            return {
              key: `${item.OutStorgeID}`,
              value: `${item.OutStorgeName}`,
            };
          });
          setOutStorageOptions(outStoragesOptions);
        } catch (error) {
          console.log("Error fetching out storages:", error);
          setModalContent({
            title: "שגיאה",
            content: "בעיה במשיכת בתי-קירור אפשריים, אנא נסו שנית.",
          });
          setShowModal(true);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [showOutStorageScan, outStorageOptions]);

  // console.log(tableRows);
  useEffect(() => {
    if (selectedOutStorage) {
      console.log(
        "fetching all open orders parts from selected out storage..."
      );
      setShowOverlayLoader(true);
      (async () => {
        try {
          const outStorageOrderPartsData = await axios.get(
            `${fetchConf}/getOpenOutStorageOrdersLines`,
            {
              params: {
                outStorageId: selectedOutStorage?.key,
              },
              timeout: 8000,
            }
          );
          const resolvedOrdersParts = await outStorageOrderPartsData.data;
          // console.log(resolvedOrdersParts);
          const tableRowsByOrder = {};
          for (let order in resolvedOrdersParts) {
            tableRowsByOrder[order] = {
              supplyDate: resolvedOrdersParts[order].supplyDate,
            };
            tableRowsByOrder[order].lines = resolvedOrdersParts[
              order
            ]?.lines?.map((part) => {
              const color =
                part.Scanned > 0
                  ? part.Scanned >= part.Platforms
                    ? COLORS.ALL_PLATFORMS_SCANNED
                    : COLORS.SOME_PLATFORMS_SCANNED
                  : COLORS.NO_PLATFORMS_SCANNED;
              return [
                part.GenerlDes,
                part.Platforms,
                part.Scanned,
                part.Comment,
                part.PartID,
                part.MistachID,
                color,
              ];
            });
          }
          setTableRows(tableRowsByOrder);
        } catch (error) {
          console.log("Error fetching open orders:", error);
          setModalContent({
            title: "שגיאה",
            content: "בעיה במשיכת הזמנות פתוחות.",
          });
          setShowModal(true);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [selectedOutStorage, resetOrdersLines]);
  // console.log(platformData);
  const platformCartonsCheck = (barcode) => {
    (async () => {
      setShowOverlayLoader(true);
      try {
        const cartonsData = await axios.get(`${fetchConf}/getPlatformData`, {
          params: {
            scanValue: barcode,
            outStorageId: selectedOutStorage.key,
            monitorData: {
              userId: user.userId,
              username: user.username,
              mistachId: scanValue || barcodeInput,
              outStorageName: selectedOutStorage.value,
              outStorageId: selectedOutStorage.key,
              source: scanValue ? "מצלמה" : "מקלדת",
            },
          },
          timeout: 7000,
        });
        const resolvedData = await cartonsData.data;
        let cartonsQuantity = 0;
        for (const item of resolvedData) cartonsQuantity += item.Qunt;
        // const cartonsQuantity = await cartonsData.data?.Qunt;
        if (cartonsQuantity) {
          setNumberOfCartons(cartonsQuantity);
          setPlatformData(resolvedData);
        } else {
          setScanValue(null);
          setBarcodeInput("");
          setPlatformData(null);
          setModalContent({
            title: "ברקוד לא נמצא",
            content: "הברקוד לא נמצא בנתוני המשטחים, אנא נסו שנית.",
          });
          setShowModal(true);
        }
      } catch (error) {
        console.log("Error fetching number of cartons:", error);
        setModalContent({
          title: "שגיאה",
          content: "בעיה בתהליך זיהוי הברקוד, אנא נסו שנית.",
        });
        setShowModal(true);
      } finally {
        setShowOverlayLoader(false);
      }
    })();
  };
  useEffect(() => {
    if (scanValue) {
      platformCartonsCheck(scanValue);
    }
  }, [scanValue]);

  const isOutStorageSelected = () => {
    if (!selectedOutStorage) {
      setModalContent({
        title: "לא נבחר בית קירור",
        content: "יש לבחור בית קירור לפני ביצוע הסריקה או הזנת ברקוד.",
      });
      setShowModal(true);
      return false;
    } else {
      return true;
    }
  };
  const handleScan = () => {
    if (isOutStorageSelected()) {
      setShowBarcodeComponent(true);
      setBarcodeInput("");
      setNumberOfCartons("");
    }
  };
  const onDetect = (result) => {
    if (showBarcodeComponent) {
      setScanValue(result.codeResult.code);
      // setScannedPlatforms((prev) => [...prev, result.codeResult.code]);
      setShowBarcodeComponent(false);
    }
  };

  // console.log(tableRows, platformData);
  const isScannedPlatformInOpenOrder = () => {
    if (tableRows) {
      // first check if platform id from platformData already assigned to one of the lines of table rows
      // console.log(tableRows);
      for (let order in tableRows) {
        for (let part of tableRows[order]?.lines) {
          for (let i = 0; i < platformData?.length; i++) {
            if (part[5] === platformData[i].MistachID) {
              return {
                order: order,
                ourPartId: part[part.length - 3],
                line: tableRows[order].lines.indexOf(part) + 1,
                scanned: part[2],
              };
            }
          }
        }
      }

      // Making sure to skip blended platforms in orders (when index 5 is null) and the platform itself not a blended one
      for (let order in tableRows) {
        for (let part of tableRows[order]?.lines) {
          // This is the logic required, search in all parts of open orders one of the platformData(id's)
          for (let i = 0; i < platformData?.length; i++) {
            if (
              !part[5] &&
              platformData[i].MistachTypeID == 1 &&
              part[part.length - 3] === platformData[i]?.OurPartID
            ) {
              if (part[2] < part[1]) {
                return {
                  order: order,
                  ourPartId: part[part.length - 3],
                  line: tableRows[order].lines.indexOf(part) + 1,
                  scanned: part[2],
                };
              }
            }
          }
        }
      }
    }
    return false;
  };
  const sendMonitorData = async (monitorData) => {
    try {
      await axios.post(`${fetchConf}/monitorScan`, monitorData);
    } catch (error) {
      console.log("Error sending monitor data: ", error);
    }
  };

  const handleSend = async () => {
    const barcodeValue = scanValue ? scanValue : barcodeInput;
    let monitorData = {
      username: user.username,
      userId: user.userId,
      mistachId: null,
      mistachExist: "false",
      outStorageName: selectedOutStorage?.value,
      outStorageId: selectedOutStorage?.key,
      source: null,
      mistachTypeId: null,
      successfulScan: "false",
      scanMessage: "",
      outOrderId: null,
      orderClosed: "false",
    };
    // console.log(platformData);
    if (barcodeValue && numberOfCartons && platformData) {
      monitorData.mistachId = barcodeValue;
      monitorData.mistachExist = "true";
      monitorData.source = scanValue ? "מצלמה" : "מקלדת";
      monitorData.mistachTypeId = platformData.length
        ? platformData[0].MistachTypeID
        : null;
      const { order, ourPartId, line, scanned } =
        isScannedPlatformInOpenOrder();
      //consider get also line number from isScannedPlatformInOpenOrder so not needed in server
      if (order) {
        monitorData.outOrderId = order;
        setShowOverlayLoader(true);
        try {
          // console.log(platformData);
          const scanData = {
            orderId: order,
            // outStorageId: selectedOutStorage.key,
            // outStorageName: selectedOutStorage.value,
            scanValue: barcodeValue,
            mistachId: platformData[0]?.MistachID,
            userId: user.userId,
            username: user.username,
            numOfCartons: numberOfCartons,
            ourPartId: ourPartId,
            line: line,
            scanned,
          };
          // console.log(scanData);
          const sendingStatus = await axios.post(`${fetchConf}/platformScan`, {
            scanData,
            monitorData,
          });
          // console.log(await sendingStatus.data);
          monitorData = { ...(await sendingStatus.data?.monitorData) };
          if (await sendingStatus?.data?.status) {
            setModalContent({
              title: "סריקה נשלחה בהצלחה",
              content: "הסריקה נקלטה בשרת.",
            });
            setShowModal(true);
            setBarcodeInput("");
            setNumberOfCartons("");
            setPlatformData(null);
            setResetOrdersLines((prev) => !prev);
            monitorData.scanMessage = "הסריקה נקלטה בשרת.";
          } else {
            const msg =
              "בעיה בשליחת נתונים לשרת אנא העבר/י למנהל את פרטי הסריקה.";
            setModalContent({
              title: "שגיאה בשליחת סריקה",
              content: msg,
            });
            setShowModal(true);
            monitorData.scanMessage = msg;
          }
          setScanValue(null);
        } catch (error) {
          console.log("Error sending scan to DataBase: ", error);
          const msg =
            "בעיה בשליחת נתונים לשרת אנא וודאו כי משטח זה לא נקלט בעבר.";
          setModalContent({
            title: "שגיאה בשליחת ברקוד",
            content: msg,
          });
          setShowModal(true);
          monitorData.scanMessage = msg;
          return false;
        } finally {
          setShowOverlayLoader(false);
        }
      } else {
        const msg = `לא קיימת הזמנה פתוחה לבית קירור זה עם ${
          platformData[0].MistachTypeID == 2 ? "משטח מעורב זה" : "המשטח שנקלט"
        }, אנא נסו שנית.`;
        setModalContent({
          title: "שגיאה בשליחת ברקוד",
          content: msg,
        });
        setShowModal(true);
        monitorData.scanMessage = msg;
      }
    } else {
      const msg = "וודאו כי התקבלו פריטים יחד עם מספר קרטונים תקין";
      setModalContent({
        title: "שגיאה בשליחת סריקה",
        content: msg,
      });
      setShowModal(true);
      monitorData.scanMessage = msg;
    }
    //send monitor data after all scan logic
    try {
      console.log("Sending monitor scan data...");
      sendMonitorData(monitorData);
    } catch (error) {
      console.log("Error sending monitor data in send: ", error);
    }
  };

  const handleBarcodeInputCheck = () => {
    if (isOutStorageSelected()) {
      setScanValue(null);
      platformCartonsCheck(barcodeInput);
    }
  };

  const tableHeaders = ["שם המוצר", "כמות משטחים", "נקלטו", "הערה"];
  const tables = tableRows
    ? Object.keys(tableRows)?.map((order) => {
        return (
          <View key={order}>
            <Text>
              הזמנה מספר: {order} לתאריך:{" "}
              {new Date(tableRows[order]?.supplyDate).toLocaleDateString("he")}
            </Text>
            <MyTable
              show={selectedOutStorage}
              headers={tableHeaders}
              rows={tableRows[order].lines}
              style={{ marginBottom: 16 }}
              rowIdentifier={order}
            />
          </View>
        );
      })
    : null;

  if (showOutStorageScan)
    return (
      <ScrollView
        vertical={true}
        style={{ backgroundColor: "#fff", minHeight: "100%" }}
      >
        <View
          component="main"
          maxWidth="xs"
          style={{ overflowX: "hidden", height: "100%" }}
        >
          <Typography component="h1" variant="h5" style={{ marginBottom: 16 }}>
            סריקת משטח מבית קירור
          </Typography>
          <View style={{ marginBottom: 16 }}>
            <SelectList
              setSelected={(item) =>
                setSelectedOutStorage(
                  outStorageOptions?.filter((option) => {
                    return option.key === item;
                  })[0]
                )
              }
              save="key"
              data={outStorageOptions}
              placeholder="בחר/י בית קירור"
              searchPlaceholder="חיפוש"
            />
          </View>
          {tables && (
            <ScrollView style={{ maxHeight: 200 }}>{tables}</ScrollView>
          )}

          <View
            style={{
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginLeft: 5,
              marginRight: 5,
              marginTop: "20px",
              marginBottom: "20px",
            }}
          />
          <View>
            <Text>סריקת משטח או הקלדה ידנית:</Text>
            <Button
              variant="outlined"
              // id="searchOrderBtn"
              //   style={{ right: "5px" }}
              color="primary"
              onClick={handleScan}
            >
              <FontAwesomeIcon icon={faBarcode} />
            </Button>
            <View style={{ flexDirection: "row" }}>
              <TextInput
                onChangeText={(text) => {
                  setBarcodeInput(text);
                  setPlatformData(null);
                }}
                value={barcodeInput}
                // keyboardType="number-pad"
                style={{
                  flex: 1,
                  borderWidth: 1,
                  padding: 4,
                  borderRadius: 4,
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                  marginTop: 8,
                  direction: "rtl",
                }}
                placeholder="הקלד/י ברקוד..."
              />
              <View
                style={{
                  justifyContent: "center",
                  borderWidth: 1,
                  borderRadius: 4,
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0,
                  marginTop: 8,
                }}
              >
                <TouchableOpacity onPress={handleBarcodeInputCheck}>
                  <FontAwesomeIcon icon={faCheck} size="lg" />
                </TouchableOpacity>
              </View>
            </View>
          </View>
          {showBarcodeComponent && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <BarcodeReader
                  style={{ marginTop: "5px" }}
                  onDetect={onDetect}
                />
                <Button
                  variant="outlined"
                  // id="searchOrderBtn"
                  style={{ right: "5px" }}
                  color="primary"
                  onClick={() => {
                    setShowBarcodeComponent(false);
                  }}
                >
                  סגור
                </Button>
              </Box>
            </>
          )}
          {/* {scannedPlatforms.length > 0 && (
            <>
              <Text>משטחים:</Text>
            </>
          )} */}
          {/* {(scanValue !== null || barcodeInput !== "") && ( */}
          {platformData && (
            <View
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              <Text>פריטים:</Text>
              {platformData?.map((platform, index) => (
                <Text key={index}>{platform?.PartDes}</Text>
              ))}
              {scanValue && (
                <View style={{ marginTop: 8 }}>
                  <Text>הערך שנסרק: </Text>
                  <Text style={{ fontWeight: "bold", marginVertical: 8 }}>
                    {scanValue}
                  </Text>
                </View>
              )}
              <Text style={{ marginTop: 8 }}>מספר קרטונים: </Text>
              <TextInput
                onChangeText={(text) => setNumberOfCartons(text)}
                // value={numberOfCartonst}
                keyboardType="number-pad"
                style={{
                  borderWidth: 1,
                  padding: 10,
                  borderRadius: 8,
                  width: 50,
                  hight: 50,
                  marginTop: 8,
                }}
                placeholder={`${numberOfCartons}`}
              />
            </View>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
            style={{
              marginTop: "25px",
              marginBottom: "25px",
            }}
          >
            אישור סריקה
          </Button>

          <View style={{ alignItems: "center" }}>
            <BackToNavBtn
              setShowCurr={setShowOutStorageScan}
              setShowButtons={setShowButtons}
            />
          </View>
          <Modal
            show={showModal}
            content={modalContent.content}
            title={modalContent.title}
            buttonText="אישור"
            toggleShowModal={setShowModal}
          />
        </View>
      </ScrollView>
    );
  else return null;
}
