import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyleSheet, Text, View } from "react-native";

// const headers = ["עניינים", "חברים"];
// const rows = [
//   ["1ב", "1א"],
//   ["2ב", "2א"],
//   ["3ב", "3א"],
// ];

export default function MyTable({ show, headers, rows, rowIdentifier, style }) {
  if (show) {
    // orderData("2001112");
    return (
      <View style={{ writingDirection: "rtl", maxHeight: "33vh", ...style }}>
        <TableContainer component={Paper}>
          <Table
            stickyHeader
            size="small"
            aria-label="a dense table"
            style={{
              width: "100%",
              overflowX: "scroll",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <TableHead>
              <TableRow>
                {headers.map((header) => {
                  return (
                    <TableCell key={header} align="left">
                      {header}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow
                  key={`${rowIdentifier}-${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {row?.slice(0, row.length - 3)?.map((item, index) => (
                    <TableCell
                      key={`${rowIdentifier}-${index}-${headers[index]}`}
                      align="left"
                      style={{ backgroundColor: row[row.length - 1] }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </View>
    );
  } else return null;
}
