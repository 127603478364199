import { View, Text, ScrollView } from "react-native";
import React, { useState, useEffect } from "react";
// import BackToNavBtn from "../BackToNavBtn/BackToNavBtn";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { MyTable, BackToNavBtn } from "../index";
import COLORS from "../../constants/COLORS";
import fetchConf from "../../fetchConfig";
const axios = require("axios");

export default function AllOpenOutStoragesOrders({
  setShowAllOutStoragesOrders,
  setShowButtons,
  showAllOutStoragesOrders,
  setShowOverlayLoader,
}) {
  const [dialogHeader, setDialogHeader] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [OutStoragesOptions, setOutStoragesOptions] = useState(null);
  const [tableRows, setTableRows] = useState({});

  useEffect(() => {
    if (!OutStoragesOptions && showAllOutStoragesOrders) {
      setShowOverlayLoader(true);
      (async () => {
        try {
          const outStoragesData = await axios.get(
            `${fetchConf}/getOutStorages`
          );
          const resolvedOutStorages = await outStoragesData?.data;
          //   console.log(resolvedOutStorages);
          const outStoragesOptions = resolvedOutStorages?.map((item) => {
            return {
              id: `${item.OutStorgeID}`,
              name: `${item.OutStorgeName}`,
            };
          });
          setOutStoragesOptions(outStoragesOptions);
        } catch (error) {
          console.log("Error fetching out storages options: ", error);
          setDialogHeader("שגיאה");
          setDialogContent(
            `בעיה במשיכת בתי קירור אפשריים לטובת הצגת הזמנות פתוחות`
          );
          setShowDialog(true);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (OutStoragesOptions) {
      console.log("fetching all open orders parts from out storages...");
      for (let outStorage of OutStoragesOptions) {
        setShowOverlayLoader(true);
        (async () => {
          try {
            const outStorageOrderPartsData = await axios.get(
              `${fetchConf}/getOpenOutStorageOrdersLines`,
              {
                params: {
                  outStorageId: outStorage?.id,
                },
                timeout: 8000,
              }
            );
            const resolvedOrdersParts = await outStorageOrderPartsData.data;
            // console.log(resolvedOrdersParts);
            const tableRowsByOrder = {};
            for (let order in resolvedOrdersParts) {
              tableRowsByOrder[order] = {
                supplyDate: resolvedOrdersParts[order].supplyDate,
              };
              tableRowsByOrder[order].lines = resolvedOrdersParts[order]?.lines
                ?.filter((part) => part.Scanned < part.Platforms)
                ?.map((part) => {
                  // console.log(part);
                  const color =
                    part.Scanned == 0
                      ? COLORS.NO_PLATFORMS_SCANNED
                      : COLORS.SOME_PLATFORMS_SCANNED;
                  return [
                    part.GenerlDes,
                    part.Platforms,
                    part.Scanned,
                    part.Comment,
                    part.PartID,
                    color,
                  ];
                });
            }
            // console.log("tableRowsByOrder: ", tableRowsByOrder);

            setTableRows((prev) => {
              return {
                ...prev,
                [outStorage.name]: tableRowsByOrder,
              };
            });
          } catch (error) {
            console.log("Error fetching open orders:", error);
            setDialogHeader("שגיאה");
            setDialogContent(
              `בעיה במשיכת נתוני הזמנות של בית קירור ${outStorage.name}`
            );
            setShowDialog(true);
          } finally {
            setShowOverlayLoader(false);
          }
        })();
      }
    }
  }, [OutStoragesOptions]);

  const tableHeaders = ["שם המוצר", "כמות משטחים", "נקלטו", "הערה"];
  const tables = tableRows
    ? Object.keys(tableRows)?.map((outStorage) => {
        return Object.keys(tableRows[outStorage])?.map((order) => {
          return (
            <View key={order}>
              <Text>
                הזמנה מספר: {order} לתאריך:{" "}
                {new Date(
                  tableRows[outStorage][order]?.supplyDate
                ).toLocaleDateString("he")}{" "}
                מבית קירור {outStorage}
              </Text>
              <MyTable
                show={true}
                headers={tableHeaders}
                rows={tableRows[outStorage][order].lines}
                style={{
                  marginBottom: 16,
                  border: "1px solid #ccc",
                  borderRadius: 4,
                }}
                rowIdentifier={order}
              />
            </View>
          );
        });
      })
    : null;
  // console.log(tables);
  if (showAllOutStoragesOrders)
    return (
      <ScrollView
        vertical={true}
        style={{ backgroundColor: "#fff", minHeight: "100%" }}
      >
        <View
          component="main"
          maxWidth="xs"
          style={{ overflowX: "hidden", height: "100%" }}
        >
          <Typography component="h1" variant="h5" style={{ marginBottom: 16 }}>
            הזמנות פתוחות מבית קירור{" "}
          </Typography>
          {tables}
          <View style={{ alignItems: "center" }}>
            <BackToNavBtn
              setShowCurr={setShowAllOutStoragesOrders}
              setShowButtons={setShowButtons}
            />
          </View>
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <DialogTitle>{dialogHeader}</DialogTitle>
            <DialogContent>
              <DialogContentText>{dialogContent}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowDialog(false);
                }}
              >
                סגור
              </Button>
            </DialogActions>
          </Dialog>
        </View>
      </ScrollView>
    );
  else return null;
}
