import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TextInput,
  useWindowDimensions,
} from "react-native";
import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { SelectList } from "react-native-dropdown-select-list";
import { BackToNavBtn, ButtonsSwitch, InventoryTable } from "../index";

import fetchConf from "../../fetchConfig";
const axios = require("axios");
const companyOptions = [
  {
    value: 1,
    content: "קנקון",
  },
  {
    value: 2,
    content: "איב",
  },
];

export default function OutStoragesInventory({
  showOutStoragesInventory,
  setShowOutStoragesInventory,
  setShowButtons,
  Modal,
  setShowOverlayLoader,
  setEivInCancun,
}) {
  const [selectedOutStorage, setSelectedOutStorage] = useState(null);
  const [outStorageOptions, setOutStorageOptions] = useState(null);
  const [company, setCompany] = useState(1);
  const [partDes, setPartDes] = useState("");
  const [inventory, setInventory] = useState([]);
  const [modalContent, setModalContent] = useState({});
  const [showModal, setShowModal] = useState(false);
  //   const { height } = Dimensions.get("window");
  const { height, width } = useWindowDimensions();
  //   console.log(inventory);
  useEffect(() => {
    if (showOutStoragesInventory) {
      console.log("fetching open orders...");
      (async () => {
        try {
          const outStoragesData = await axios.get(
            `${fetchConf}/getOutStorages`
          );
          const resolvedOutStorages = await outStoragesData?.data;
          //   console.log(resolvedOutStorages);
          const outStoragesOptions = resolvedOutStorages?.map((item) => {
            return {
              key: `${item.OutStorgeID}`,
              value: `${item.OutStorgeName}`,
            };
          });
          setOutStorageOptions(outStoragesOptions);
        } catch (error) {
          console.log("Error fetching out storages:", error);
          setModalContent({
            title: "שגיאה",
            content: "בעיה במשיכת בתי-קירור אפשריים, אנא נסו שנית.",
          });
          setShowModal(true);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [showOutStoragesInventory]);

  useEffect(() => {
    if (selectedOutStorage) {
      console.log("fetching inventory...");
      (async () => {
        try {
          const inventoryData = await axios.get(
            `${fetchConf}/getOutStorageInventory`,
            {
              params: {
                compId: company,
                outStorageId: parseInt(selectedOutStorage.key),
                partDes: partDes,
              },
              timeout: 5000,
            }
          );
          const resolvedInventory = await inventoryData.data;

          setInventory(
            resolvedInventory.map((part) => {
              return {
                partDes: part.PartDes,
                ourPartId: part.OurPartID,
                sq: part.SQ,
                cq: part.CQ,
                sw: part.SW,
                expiryDate: part.ExpiryDate,
              };
            })
          );
        } catch (error) {
          console.log("Error fetching inventory:", error);
          setModalContent({
            title: "שגיאה",
            content: "בעיה במשיכת מלאי של בית קירור, אנא נסו שנית.",
          });
          setShowModal(true);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [selectedOutStorage, partDes, company]);

  useEffect(() => {
    setEivInCancun(company === 2 ? true : false);
  }, [company]);

  const headerArray = [
    { name: "תאור", sort: true },
    { name: "קוד", sort: false },
    { name: "כמות", sort: false },
    { name: "משטחים", sort: false },
    { name: "משקל", sort: false },
    { name: "ת.תפוגה", sort: true },
  ];
  return (
    <ScrollView
      vertical={true}
      style={{ backgroundColor: "#fff", minHeight: "100%" }}
    >
      <View
        component="main"
        maxWidth="xs"
        style={{ overflowX: "hidden", height: "100%" }}
      >
        <Typography component="h1" variant="h5" style={{ marginBottom: 16 }}>
          מלאי בתי קירור
        </Typography>
        <View style={{ marginBottom: 16 }}>
          <SelectList
            setSelected={(item) => {
              setSelectedOutStorage(
                outStorageOptions?.filter((option) => {
                  return option.key === item;
                })[0]
              );
            }}
            save="key"
            data={outStorageOptions ?? []}
            placeholder="בחר/י בית קירור"
            searchPlaceholder="חיפוש"
          />
        </View>
        <View style={styles.rowComponent}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Typography variant="subtitle1">חברה:</Typography>
            <ButtonsSwitch
              defaultValue={company}
              setValue={setCompany}
              options={companyOptions}
            />
          </View>
          <View style={styles.verticalLine}></View>
          <TextInput
            placeholder="חיפוש פריט"
            onChangeText={(text) => setPartDes(text)}
            value={partDes}
            style={styles.partDesInput}
          />
        </View>

        {inventory.length ? (
          <>
            <View
              style={[
                styles.tableContainer,
                { maxHeight: width > height ? height * 0.8 : height / 2 },
              ]}
            >
              <InventoryTable headerArray={headerArray} rowsArray={inventory} />
            </View>
            {width < height && <Text>*לצפייה אופטימלית ניתן לסובב מסך</Text>}
          </>
        ) : (
          selectedOutStorage && (
            <Text>*לא נמצא מלאי עבור הפרמטרים הנתונים...</Text>
          )
        )}
        <View style={styles.footerButtons}>
          <BackToNavBtn
            setShowCurr={setShowOutStoragesInventory}
            setShowButtons={setShowButtons}
          />
        </View>
        <Modal
          show={showModal}
          content={modalContent.content}
          title={modalContent.title}
          buttonText="אישור"
          toggleShowModal={setShowModal}
        ></Modal>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  footerButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  rowComponent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  partDesInput: {
    borderWidth: 1,
    padding: 12,
    borderRadius: 4,
    borderColor: "grey",
    textAlign: "right",
    width: "50%",
  },
  verticalLine: {
    height: 36,
    width: 1,
    backgroundColor: "#ccc",
  },
  tableContainer: {
    borderWidth: 1,
    borderColor: "grey",
  },
  tableCell: { textOverflow: "ellipsis", maxWidth: "5px", overflow: "hidden" },
});
