import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import { useState, useEffect } from "react";
import { Typography, IconButton } from "@material-ui/core";
import { BackToNavBtn, DatePicker } from "../index";
import { SelectList } from "react-native-dropdown-select-list";
import { DeficienciesTable } from "../index";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";
import fetchConf from "../../fetchConfig";
const axios = require("axios");
const dayBeforeYesterday = new Date(
  new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 2 * 24 * 60 * 60 * 1000
);
const headers = ["פריט", "סוג", "הוזמן", "סופק", "הפרש"];

export default function Deficiencies({
  user,
  Modal,
  setShowButtons,
  setShowDeficiencies,
  showDeficiencies,
  setShowOverlayLoader,
}) {
  const [modalContent, setModalContent] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState(dayBeforeYesterday);
  const [endDateFilter, setEndDateFilter] = useState(new Date());
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [resetCustomer, setResetCustomer] = useState(false);
  const [deficienciesData, setDeficienciesData] = useState({});
  const [deficienciesTables, setDeficienciesTables] = useState(null);
  const { height } = useWindowDimensions();
  useEffect(() => {
    if (deficienciesData) {
      console.log("Updating customers and tables...");
      const custOptions = [];
      for (let orderId of Object.keys(deficienciesData)) {
        for (let partId of Object.keys(deficienciesData[orderId])) {
          custOptions.push({
            key: deficienciesData[orderId][partId]?.CustomerID,
            value: deficienciesData[orderId][partId]?.CustomerName,
          });
          break;
        }
      }
      setCustomerOptions(custOptions);
    }
  }, [deficienciesData]);

  useEffect(() => {
    if (deficienciesData) {
      setDeficienciesTables(
        Object.keys(deficienciesData)
          ?.filter((orderId) => {
            return selectedCustomerId
              ? deficienciesData[orderId][
                  Object.keys(deficienciesData[orderId])[0]
                ].CustomerID == selectedCustomerId
              : true;
          })
          ?.map((orderId) => {
            let date;
            const rows = Object.keys(deficienciesData[orderId])?.map(
              (partId) => {
                const { PartDes, UnitsDes, Ordered, supplied, Date } =
                  deficienciesData[orderId][partId];
                date = Date;
                return [
                  PartDes,
                  UnitsDes,
                  Ordered,
                  supplied ?? 0,
                  Ordered - (supplied ?? 0),
                ];
              }
            );
            return (
              <View style={{ marginBottom: 16 }} key={orderId}>
                <Text>
                  הזמנה מס': {orderId} מתאריך:{" "}
                  {new Date(date).toLocaleDateString("he")}
                </Text>
                <DeficienciesTable headers={headers} rows={rows} />
              </View>
            );
          })
      );
    }
  }, [selectedCustomerId, deficienciesData]);

  useEffect(() => {
    if (showDeficiencies) {
      console.log("Fetching agent orders deficiencies data...");
      setShowOverlayLoader(true);
      (async () => {
        try {
          const ordersDeficiencies = await axios.post(
            `${fetchConf}/deficiencies`,
            {
              userId: user?.userId,
              startDate: moment(
                startDateFilter,
                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
              ).format("YYYY-MM-DD HH:mm:ss"),
              endDate: moment(
                endDateFilter,
                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
              ).format("YYYY-MM-DD HH:mm:ss"),
            }
          );
          setDeficienciesData(await ordersDeficiencies?.data);
        } catch (error) {
          setModalContent({
            title: "שגיאה",
            content: "שגיאה במשיכת נתוני חוסרים.",
          });
          setShowModal(true);
          console.log("Error fetching deficiencies data: ", error);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [showDeficiencies, startDateFilter, endDateFilter]);

  const handleDeselectCustomer = () => {
    if (selectedCustomerId) {
      setResetCustomer((prev) => !prev);
      setSelectedCustomerId(null);
    }
  };

  return (
    <ScrollView vertical={true} style={styles.rootComponent}>
      <View component="main" maxWidth="xs" style={styles.mainComponent}>
        <View style={styles.header}>
          <Typography component="h1" variant="h5">
            חוסרים בהזמנות
          </Typography>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 16,
          }}
        >
          <DatePicker
            label={"חשבונית מתאריך"}
            value={startDateFilter}
            setValue={setStartDateFilter}
            maxDate={endDateFilter}
            style={{ width: "48%" }}
          />
          <DatePicker
            label={"עד תאריך"}
            value={endDateFilter}
            setValue={setEndDateFilter}
            minDate={startDateFilter}
            style={{ width: "48%" }}
          />
        </View>
        <View
          style={{
            marginBottom: 16,
            flexDirection: "row",
            // justifyContent: "space-between",
          }}
        >
          <View style={{ flex: 1 }}>
            <SelectList
              setSelected={(key) => setSelectedCustomerId(key)}
              save="key"
              data={customerOptions}
              placeholder="סינון לפי לקוח"
              searchPlaceholder="חיפוש"
              key={resetCustomer}
              notFoundText={"לא נמצאו הזמנות של לקוחות בתאריכים אלו..."}
            />
          </View>
          <View style={{ justifyContent: "center", height: 46 }}>
            <IconButton onClick={handleDeselectCustomer}>
              <HighlightOffIcon />
            </IconButton>
          </View>
        </View>
        <ScrollView style={{ maxHeight: height * 0.4 }}>
          {deficienciesTables}
        </ScrollView>
        <View style={styles.footerButtons}>
          <BackToNavBtn
            setShowCurr={setShowDeficiencies}
            setShowButtons={setShowButtons}
          />
        </View>
        <Modal
          show={showModal}
          content={modalContent.content}
          title={modalContent.title}
          buttonText="אישור"
          toggleShowModal={setShowModal}
          //   speacilFunction={modalContent.speacilFunction}
        ></Modal>
      </View>
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  rootComponent: {
    backgroundColor: "#fff",
    // minHeight: "100%",
    flex: 1,
  },
  mainComponent: {
    overflowX: "hidden",
    // height: ,
    flex: 1,
  },
  header: {
    marginBottom: 16,
  },
  footerButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});
