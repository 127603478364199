import { View, Text } from "react-native";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function StatusIcon({ color, icon }) {
  return (
    <View
      style={{
        borderWidth: 2,
        borderRadius: "50%",
        borderColor: color,
        padding: 8,
        width: 50,
        height: 50,
      }}
    >
      <FontAwesomeIcon icon={icon} size="10x" color={color} />
    </View>
  );
}
