import { View, Text } from "react-native";
import { useState } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

const useStyles = makeStyles({
  stickyCell: {
    top: 0,
    zIndex: 2,
    position: "sticky",
    backgroundColor: "#edf4fb",
  },
});

export default function InventoryTable({ headerArray, rowsArray }) {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState(null);
  const [orderDirection, setOrderDirection] = useState("asc");

  const createSortHandler = (property) => {
    if (!(orderBy === property && orderDirection === "desc")) {
      setOrderBy(property);
      setOrderDirection(
        orderBy === property && orderDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setOrderBy(null);
      setOrderDirection("asc");
    }
  };

  const descendingComparator = (a, b, valueToOrderBy) => {
    const property = valueToOrderBy === "תאור" ? "partDes" : "expiryDate";
    if (b[property] < a[property]) return -1;
    if (b[property] > a[property]) return 1;
    return 0;
  };

  const getComparator = (order, valueToOrderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, valueToOrderBy)
      : (a, b) => -descendingComparator(a, b, valueToOrderBy);
  };

  const sortedRow = (row, comparator) => {
    const stabilizedRow = row.map((item, index) => [item, index]);
    stabilizedRow.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRow.map((row) => row[0]);
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headerArray.map((header, index) => {
              return (
                <TableCell
                  align="right"
                  className={classes.stickyCell}
                  key={header.name}
                >
                  {header.sort ? (
                    <TableSortLabel
                      active={orderBy === header.name}
                      direction={
                        orderBy === header.name ? orderDirection : "asc"
                      }
                      onClick={() => {
                        createSortHandler(header.name);
                      }}
                      style={{ flexDirection: "row" }}
                    >
                      {header.name}
                    </TableSortLabel>
                  ) : (
                    header.name
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rowsArray?.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="right">{row.partDes}</TableCell>
              <TableCell align="right">{row.ourPartId}</TableCell>
              <TableCell align="right">{row.sq}</TableCell>
              <TableCell align="right">{row.cq}</TableCell>
              <TableCell align="right">
                <View>
                  <Text
                    numberOfLines={1}
                    style={{ flexShrink: 1, maxWidth: 40 }}
                    onPress={(event) => {
                      if (event.currentTarget.style.maxWidth == "40px") {
                        event.currentTarget.style.maxWidth = "80px";
                      } else {
                        event.currentTarget.style.maxWidth = "40px";
                      }
                    }}
                  >
                    {row.sw}
                  </Text>
                </View>
              </TableCell>
              <TableCell align="right">
                {new Date(row.expiryDate).toLocaleDateString("he")}
              </TableCell>
            </TableRow>
          ))} */}
          {sortedRow(rowsArray, getComparator(orderDirection, orderBy)).map(
            (row, index) => (
              <TableRow key={index}>
                <TableCell align="right">{row.partDes}</TableCell>
                <TableCell align="right">{row.ourPartId}</TableCell>
                <TableCell align="right">{row.sq}</TableCell>
                <TableCell align="right">{row.cq}</TableCell>
                <TableCell align="right">
                  <View>
                    <Text
                      numberOfLines={1}
                      style={{ flexShrink: 1, maxWidth: 40 }}
                      onPress={(event) => {
                        if (event.currentTarget.style.maxWidth == "40px") {
                          event.currentTarget.style.maxWidth = "80px";
                        } else {
                          event.currentTarget.style.maxWidth = "40px";
                        }
                      }}
                    >
                      {row.sw}
                    </Text>
                  </View>
                </TableCell>
                <TableCell align="right">
                  {new Date(row.expiryDate).toLocaleDateString("he")}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
