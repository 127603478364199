import { View, Text, ScrollView } from "react-native";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    flexDirection: "column",
    paddingTop: 0,
    // alignItems: "center",
  },
  dialogContent: {
    padding: 16,
    overflowX: "hidden",
  },
}));

export default function LastOrders({
  openLastOrders,
  customerLastOrders,
  setOpenLastOrders,
  handleOrderSelected,
}) {
  // console.log(customerLastOrders);

  const classes = useStyles();

  const ordersAccordion = customerLastOrders.reverse().map((orderData) => {
    return (
      <Accordion key={orderData.CustOrderID}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Text>
            הזמנה{" "}
            <Text style={{ fontWeight: "bold" }}>{orderData.CustOrderID} </Text>
            {"\n"}
            מתאריך{" "}
            <Text style={{ fontWeight: "bold" }}>
              {new Date(orderData.Date).toLocaleDateString("he")}
            </Text>
          </Text>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <View style={{ alignItems: "center", marginBottom: 8 }}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={(event) => {
                handleOrderSelected(orderData);
                setOpenLastOrders(false);
              }}
            >
              בחירה
            </Button>
          </View>
          {orderData.lines.map((line) => {
            line.checked = true;
            return (
              <View
                key={`${orderData.CustOrderID}-${line.Line}`}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderWidth: 1,
                  borderColor: "#ccc",
                  borderRadius: 4,
                  marginBottom: 4,
                  padding: 4,
                }}
              >
                <Text>{line.GenerlDes}</Text>
                <Checkbox
                  defaultChecked
                  size="small"
                  color="primary"
                  onChange={(event) => {
                    line.checked = event.target.checked;
                  }}
                />
              </View>
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <Dialog open={openLastOrders}>
      <DialogTitle>טעינת פריטים מהזמנות קודמות</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {ordersAccordion?.length > 0 ? (
          <ScrollView style={{ maxHeight: 400 }}>{ordersAccordion}</ScrollView>
        ) : (
          <Text>לא נמצאו הזמנות...</Text>
        )}
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button variant="outlined" onClick={() => setOpenLastOrders(false)}>
          סגור
        </Button>
      </DialogActions>
    </Dialog>
  );
}
