import { View, Text, StyleSheet, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { SelectList } from "react-native-dropdown-select-list";
import {
  BackToNavBtn,
  ButtonsSwitch,
  DatePicker,
  AgentsOrderParts,
} from "../index";
import fetchConf from "../../fetchConfig";
const axios = require("axios");

const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
// tomorrow.setHours(0, 0, 0, 0);
export default function NewAgentsOrder({
  setShowNewAgentsOrder,
  setShowButtons,
  user,
  showNewAgentsOrder,
  setShowOverlayLoader,
  Modal,
  setEivInCancun,
}) {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [company, setCompany] = useState(null);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [supplyDate, setSupplyDate] = useState(tomorrow);
  const [modalContent, setModalContent] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [resetCustomerSelectList, setResetCustomerSelectList] = useState(false);

  const validateMainOrderData = () => {
    if (selectedCustomer && company && supplyDate) return true;
    else {
      setModalContent({
        title: "שגיאה",
        content: "יש לוודא כי נבחר לקוח, תאריך אספקה וחברה.",
      });
      setShowModal(true);
      return false;
    }
  };

  useEffect(() => {
    if (showNewAgentsOrder) {
      console.log("Fetching companies options...");
      setShowOverlayLoader(true);
      (async () => {
        try {
          const companiesData = await axios.get(`${fetchConf}/getCompanies`);
          const resolvedCompanies = await companiesData?.data;
          setCompany(resolvedCompanies[0]?.CompID);
          setCompaniesOptions(resolvedCompanies);
        } catch (error) {
          console.log("Error fetching companies options: ", error);
          setModalContent({
            title: "שגיאה",
            content: "שגיאה במשיכת חברות אפשריות, אנא נסו שנית.",
          });
          setShowModal(true);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [showNewAgentsOrder]);

  useEffect(() => {
    if (showNewAgentsOrder && company) {
      console.log("Fetching customer options...");
      setShowOverlayLoader(true);
      setSelectedCustomer(null);
      setResetCustomerSelectList((prev) => !prev);
      (async () => {
        try {
          const customersData = await axios.get(`${fetchConf}/getCustomers`, {
            params: {
              userId: user.userId,
              compId: company,
            },
            timeout: 5000,
          });
          //   console.log(customersData);
          const resolvedCustomers = await customersData?.data;
          setCustomerOptions(
            resolvedCustomers?.map((item) => {
              return {
                key: `${item.CustomerID}`,
                value: `${item.Name}`,
                disabled: item.NotActive == 0 ? false : true,
                ...item,
              };
            })
          );
        } catch (error) {
          console.log("Error fetching customer options: ", error);
          setModalContent({
            title: "שגיאה",
            content: "שגיאה במשיכת לקוחות אפשריים, אנא נסו שנית.",
          });
          setShowModal(true);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [showNewAgentsOrder, company]);

  useEffect(() => {
    // console.log(user?.db == "Cancun");
    if (user?.db == "Cancun") setEivInCancun(company === 2 ? true : false);
  }, [company, user]);

  const onCustomerSelected = (item) => {
    setSelectedCustomer(
      customerOptions?.filter((option) => {
        return option.key === item;
      })[0]
    );
  };

  const companyOptions = companiesOptions?.map((comp) => {
    return {
      value: comp.CompID,
      content: comp.CompName?.split(" ")[0],
    };
  });
  //  [
  //   {
  //     value: 1,
  //     content: "קנקון",
  //   },
  //   {
  //     value: 2,
  //     content: "איב",
  //   },
  // ];

  const validateOrderParts = (parts) => {
    if (!parts || parts.length < 1) {
      setModalContent({
        title: "שגיאה",
        content: "לא ניתן לשלוח הזמנה ללא פריטים.",
      });
      setShowModal(true);
      return false;
    }
    for (let partData of parts) {
      //   console.log(partData);
      if (
        !(
          partData &&
          partData.key &&
          partData.agentPrice &&
          partData.unit &&
          partData.quantity
        )
      ) {
        setModalContent({
          title: "שגיאה",
          content:
            "פריטים לא תקינים, וודאו כי כל פריט מכיל פריט נבחר, סוג יחידה, כמות תקינה, ומחיר.",
        });
        setShowModal(true);
        return false;
      }
    }
    return true;
  };
  //   console.log(supplyDate);
  const handleSend = (parts) => {
    if (validateMainOrderData()) {
      if (validateOrderParts(parts)) {
        // console.log(selectedCustomer, supplyDate, user, company, parts);
        setShowOverlayLoader(true);
        (async () => {
          try {
            const orderData = {
              customerId: selectedCustomer.key,
              customerName: selectedCustomer.value,
              address: selectedCustomer.Address,
              city: selectedCustomer.City,
              zipCode: selectedCustomer.ZipCode,
              transDate: supplyDate,
              userId: user.userId,
              username: user.username,
              compId: company,
              compNum: selectedCustomer.CompNum,
              lines: [],
            };
            for (const line of parts) {
              orderData.lines.push({
                partId: line.id,
                generalDes: line.label,
                qunt: line.quantity,
                units: line.unit.key,
                kuserId: 4,
                kuserDes: "לא כשר",
                comment: line.comments ?? "",
                basePrice: line.basePrice,
                totalPrice: line.agentPrice * line.quantity,
                unitsDes: line.unit.label,
                basePriceRequest: line.agentPrice,
              });
            }
            // console.log(orderData);
            const insertStatus = await axios.post(
              `${fetchConf}/insertPendingOrder`,
              orderData
            );
            console.log("insertStatus", await insertStatus.data);
            if (insertStatus.data) {
              setModalContent({
                title: "הזמנה נקלטה",
                content: "ההזמנה תקינה ונשלחה בהצלחה!",
              });
              setShowModal(true);
              setSelectedCustomer(null);
              setResetCustomerSelectList((prev) => !prev);
              setSupplyDate(tomorrow);
            } else {
              setModalContent({
                title: "שגיאה",
                content: "הזמנה לא נקלטה, אנא נסו שנית או פנו למנהל האפליקציה",
              });
              setShowModal(true);
            }
          } catch (error) {
            console.log("Error sending order to DataBase: ", error);
            setModalContent({
              title: "שגיאה",
              content: "שגיאה בשליחת הזמנה, אנא נסו שנית",
            });
            setShowModal(true);
          } finally {
            setShowOverlayLoader(false);
          }
        })();
      }
    }
  };

  return (
    <ScrollView vertical={true} style={styles.rootComponent}>
      <View component="main" maxWidth="xs" style={styles.mainComponent}>
        <View style={styles.header}>
          <Typography component="h1" variant="h5">
            יצירת הזמנה
          </Typography>
        </View>
        <View style={{ marginBottom: 16 }}>
          <SelectList
            setSelected={(item) => onCustomerSelected(item)}
            save="key"
            data={customerOptions ?? []}
            placeholder="בחר/י לקוח"
            searchPlaceholder="חיפוש"
            key={resetCustomerSelectList}
          />
        </View>
        {selectedCustomer && (
          <View>
            <View style={styles.rowComponent}>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.customerText}>אובליגו: </Text>
                <Text>.{selectedCustomer.Obligo}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.customerText}>הערות: </Text>
                <Text>
                  {selectedCustomer.Comments
                    ? selectedCustomer.Comments
                    : "אין הערות"}
                  .
                </Text>
              </View>
            </View>
            <View style={styles.rowComponent}>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.customerText}>מצב חוב: </Text>
                <Text>לחכות לפרוצדורה...</Text>
              </View>
              <Button variant="outlined" color="primary" onClick={() => {}}>
                פירוט{" "}
              </Button>
            </View>
          </View>
        )}
        <View style={styles.rowComponent}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Typography variant="subtitle1">חברה:</Typography>
            <ButtonsSwitch
              defaultValue={company}
              setValue={setCompany}
              options={companyOptions}
            />
          </View>
          <View style={styles.verticalLine}></View>
          <DatePicker
            label={"תאריך אספקה"}
            value={supplyDate}
            setValue={setSupplyDate}
            minDate={supplyDate}
            style={{ width: "48%" }}
          />
        </View>
        <View style={styles.horizontalLine} />
        <AgentsOrderParts
          //   key={selectedCustomer}
          customer={selectedCustomer}
          company={company}
          setModalContent={setModalContent}
          setShowModal={setShowModal}
          handleSend={handleSend}
          setShowOverlayLoader={setShowOverlayLoader}
          validateMainOrderData={validateMainOrderData}
        />
        <View style={styles.footerButtons}>
          <BackToNavBtn
            setShowCurr={setShowNewAgentsOrder}
            setShowButtons={setShowButtons}
          />
        </View>
        <Modal
          show={showModal}
          content={modalContent.content}
          title={modalContent.title}
          buttonText="אישור"
          toggleShowModal={setShowModal}
          //   speacilFunction={modalContent.speacilFunction}
        ></Modal>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  rootComponent: {
    backgroundColor: "#fff",
    // minHeight: "100%",
    flex: 1,
  },
  mainComponent: {
    overflowX: "hidden",
    // height: ,
    flex: 1,
  },
  header: {
    marginBottom: 16,
  },
  customerText: {
    fontWeight: "bold",
  },
  rowComponent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  verticalLine: {
    height: 36,
    width: 1,
    backgroundColor: "#ccc",
  },
  horizontalLine: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    marginBottom: 16,
  },
  footerButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});
