import { View, Text, StyleSheet, TextInput, Dimensions } from "react-native";
import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import fetchConf from "../../fetchConfig";
import axios from "axios";

export default function OSPart({
  outStorageId,
  compId,
  setShowDialog,
  setDialogContent,
  setDialogHeader,
  index,
  addValidPart,
  setShowOverlayLoader,
}) {
  const [partObject, setPartObject] = useState(null);
  const [partDes, setPartDes] = useState("");
  const [partsOptions, setPartsOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [platformsQuantity, setPlatformsQuantity] = useState(null);
  const [comments, setComments] = useState("");
  const [blendedPlatforms, setBlendedPlatforms] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);

  // console.log(partObject);
  useEffect(() => {
    if (partDes.length >= 0 && outStorageId) {
      setShowOverlayLoader(true);
      console.log("Fetching parts options by input...");
      (async () => {
        try {
          const partsOptionsData = await axios.post(
            `${fetchConf}/getPartsForNewOutStorageOrder`,
            {
              compId: compId,
              outStorageId: parseInt(outStorageId),
              partDes: partDes,
            }
          );
          const resolvedPartOptions = await partsOptionsData.data;
          // console.log(resolvedPartOptions);
          const options = resolvedPartOptions?.map((item) => {
            return {
              key: `${item.PartID}`,
              label: `${item.SQ}-${item.CP} X ${item.PartDes}`,
              partDes: item.PartDes,
              availablePlatforms: item.CP,
              ourPartId: item.OurPartID,
            };
          });
          setPartsOptions(options);
        } catch (error) {
          console.log("Error fetching parts options:", error);
          setDialogHeader("שגיאה");
          setDialogContent("בעיה במשיכת פריטים אפשריים, אנא נסו שנית.");
          setShowDialog(true);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [partDes]);
  useEffect(() => {
    if (partObject?.key) {
      console.log("Fetching blended platforms for part...");
      setShowOverlayLoader(true);
      setSelectedPlatform(null);
      (async () => {
        try {
          const blendedPlatformsData = await axios.get(
            `${fetchConf}/getBlendedPlatforms`,
            {
              params: {
                partId: partObject?.key,
                outStorageId: outStorageId,
              },
              timeout: 5000,
            }
          );
          // console.log(blendedPlatformsData);

          const options = blendedPlatformsData.data.map((platform, index) => {
            return {
              ...platform,
              key: platform.MistachID,
              label: `${index + 1}: תאריך תפוגה: ${new Date(
                platform.ExpiryDate
              )?.toLocaleDateString("he")}, מס' קרטונים: ${platform.Qunt}`,
            };
          });
          setBlendedPlatforms(options);
        } catch (error) {
          console.log("Error fetching blended platforms", error);
          setDialogHeader("שגיאה");
          setDialogContent("בעיה במשיכת משטחים מעורבים לפריט.");
          setShowDialog(true);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [partObject]);

  const validatePartForm = () => {
    return (
      partObject &&
      platformsQuantity &&
      !isNaN(platformsQuantity) &&
      platformsQuantity > 0 &&
      platformsQuantity <= partObject.availablePlatforms
    );
  };

  const handleSavePartData = () => {
    if (validatePartForm()) {
      // console.log(partObject);
      addValidPart(index, {
        ourPartId: partObject.ourPartId,
        partId: partObject.key,
        partDes: partObject.partDes,
        platforms: platformsQuantity,
        comments: comments,
        platformId: selectedPlatform ? selectedPlatform.MistachID : null,
      });
      setOpen(false);
    } else {
      setDialogHeader("שגיאה");
      setDialogContent(
        `אנא וודאו כי נבחר פריט ומספר המשטחים תקין(בין 1 ל-${partObject.availablePlatforms}).`
      );
      setShowDialog(true);
    }
  };

  const handleExitPartForm = () => {
    setPartObject(null);
    setComments("");
    setPartDes("");
    setPlatformsQuantity(null);
    setOpen(false);
  };

  const windowWidth = Dimensions.get("window").width;
  return (
    <View style={styles.part}>
      <View
        onClick={() => {
          setOpen(true);
        }}
        style={{ flex: 1 }}
      >
        {partObject && platformsQuantity ? (
          <View>
            <Text>{`מוצר: ${partObject.partDes}`}</Text>
            <Text>{`כמות משטחים: ${platformsQuantity}`}</Text>
          </View>
        ) : (
          <Text>בחר/י מוצר</Text>
        )}
      </View>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        style={{ overflowX: "hidden" }}
      >
        <DialogTitle>הוספת פריט להזמנה</DialogTitle>
        <DialogContent
          style={{ padding: 24, width: windowWidth * 0.9, maxWidth: 300 }}
        >
          <Autocomplete
            options={partsOptions}
            renderInput={(params) => (
              <TextField {...params} label="בחר/י מוצר" />
            )}
            onChange={(event, newSelectedPart) => {
              setPartObject(newSelectedPart);
            }}
            onInputChange={(event, newValue) => setPartDes(newValue)}
          />
          <View style={{ marginVertical: 16, marginBottom: 0 }}>
            {partObject && (
              <>
                <DialogContentText>
                  {`${partObject?.partDes}`}
                </DialogContentText>
                <DialogContentText>
                  קוד מוצר: {`${partObject?.key}`}
                </DialogContentText>

                <Autocomplete
                  options={blendedPlatforms}
                  renderInput={(params) => (
                    <TextField {...params} label="בחר/י משטח מעורב" />
                  )}
                  noOptionsText="לא נמצאו משטחים מעורבים לפריט זה"
                  onChange={(_, option) => {
                    setSelectedPlatform(option);
                    setPlatformsQuantity(1);
                  }}
                  sx={{ marginBottom: 4 }}
                />
              </>
            )}
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text>מספר משטחים</Text>
            <TextInput
              onChangeText={(text) => {
                setPlatformsQuantity(text);
              }}
              value={platformsQuantity ?? ""}
              keyboardType="number-pad"
              style={styles.platformsQuantity}
              editable={!!!selectedPlatform}
            />
          </View>
          <View>
            <TextInput
              onChangeText={(text) => {
                setComments(text);
              }}
              value={comments}
              style={{
                //   flex: 1,
                borderWidth: 1,
                padding: 16,
                borderRadius: 4,
                direction: "rtl",
                marginTop: 16,
              }}
              placeholder="הערות..."
              multiline
            />
          </View>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" onClick={handleExitPartForm}>
            סגור
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSavePartData}
          >
            שמור
          </Button>
        </DialogActions>
      </Dialog>
    </View>
  );
}

const styles = StyleSheet.create({
  part: {
    border: "1px solid grey",
    borderRadius: "5px",
    width: "75%",
    padding: 8,
  },
  platformsQuantity: {
    borderWidth: 1,
    padding: 16,
    borderRadius: 4,
    marginRight: 8,
    width: 50,
    height: 50,
    // direction: "rtl",
  },
});
