import { View, Text, ScrollView, StyleSheet } from "react-native";
import React from "react";
import {
  BackToNavBtn,
  ButtonsSwitch,
  DatePicker,
  OutStorageOrderParts,
} from "../index";
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Dialog,
  Typography,
} from "@material-ui/core";

import { useState } from "react";
import { useEffect } from "react";
import { SelectList } from "react-native-dropdown-select-list";
import fetchConf from "../../fetchConfig";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function NewOutStorageOrder({
  setShowButtons,
  setShowNewOutStorageOrder,
  showNewOutStorageOrder,
  setShowOverlayLoader,
  showLoaderOverlay,
  user,
  setEivInCancun,
}) {
  const [company, setCompany] = useState("cancun");
  const [selectedOutStorage, setSelectedOutStorage] = useState(null);
  const [outStorageOptions, setOutStorageOptions] = useState(null);
  const [target, setTarget] = useState("workers");
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [openTargets, setOpenTargets] = useState(false);
  const [targetOptions, setTargetOptions] = useState([]);
  const [supplyDate, setSupplyDate] = useState(new Date());
  const [resetAutoComplete, setResetAutoComplete] = useState(false);
  const [switched, setSwitched] = useState(false);
  const [needToResetParts, setNeedToResetParts] = useState(false);
  const [dialogHeader, setDialogHeader] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const targetOptionsLoading = openTargets && targetOptions.length === 0;

  const isMainOrderValid =
    selectedOutStorage && company && target && selectedTarget && supplyDate;

  //   console.log(user);
  useEffect(() => {
    setEivInCancun(company === "eiv" ? true : false);
    setResetAutoComplete((prev) => !prev);
    setSelectedTarget(null);
    setSwitched(true);
    setNeedToResetParts(true);
    setTargetOptions([]);
  }, [company, target]);

  useEffect(() => {
    setNeedToResetParts(true);
  }, [selectedOutStorage]);

  useEffect(() => {
    if (showNewOutStorageOrder && switched) {
      setShowOverlayLoader(true);
      console.log("fetching target options...");
      (async () => {
        try {
          const targetOptionsData = await axios.get(
            `${fetchConf}/getTargetForNewOutStorageOrder`,
            {
              params: {
                target: target,
                compId: company === "cancun" ? 1 : 2,
              },
              timeout: 20000,
            }
          );
          const resolvedTargetOptions = await targetOptionsData.data;
          // console.log(resolvedTargetOptions);
          const options = resolvedTargetOptions?.map((item) => {
            return {
              key: `${target === "workers" ? item.WorkerID : item.CustomerID}`,
              value: `${target === "workers" ? item.WorkerName : item.Name}`,
              custCity: item.City,
              custAddress: item.Address,
            };
          });
          setTargetOptions(options);
        } catch (error) {
          console.log("Error fetching targets:", error);
          setDialogHeader("שגיאה");
          setDialogContent("בעיה במשיכת יעדים אפשריים, אנא נסו שנית.");
          setShowDialog(true);
        } finally {
          setShowOverlayLoader(false);
          setSwitched(false);
        }
      })();
    }
  }, [switched]);

  useEffect(() => {
    if (showNewOutStorageOrder) {
      console.log("fetching open orders...");
      try {
        (async () => {
          const outStoragesData = await axios.get(
            `${fetchConf}/getOutStorages`
          );
          const resolvedOutStorages = await outStoragesData?.data;
          //   console.log(resolvedOutStorages);
          const outStoragesOptions = resolvedOutStorages?.map((item) => {
            return {
              key: `${item.OutStorgeID}`,
              value: `${item.OutStorgeName}`,
              address: item.Address,
              city: item.City,
              country: item.Country,
              zipCode: item.ZipCode,
              phone: item.Phone,
              email: item.email,
            };
          });
          setOutStorageOptions(outStoragesOptions);
        })();
      } catch (error) {
        console.log("Error fetching out storages:", error);
        setDialogHeader("שגיאה");
        setDialogContent("בעיה במשיכת בתי-קירור אפשריים, אנא נסו שנית.");
        setShowDialog(true);
      } finally {
        setShowOverlayLoader(false);
      }
    }
  }, []);

  const validParts = (parts) => {
    // console.log(parts);
    for (let part of parts) {
      if (!isMainOrderValid || !part?.partId || !part?.platforms) return false;
    }
    if (parts && parts.length > 0) return true;
    return false;
  };
  //   console.log(selectedOutStorage);
  const handleSend = async (parts) => {
    if (validParts(parts)) {
      try {
        const toWorkers = target === "workers";
        const orderData = {
          customerId: selectedOutStorage.key,
          customerName: selectedOutStorage.value,
          setToId: toWorkers ? selectedTarget.key : null,
          setToName: toWorkers ? selectedTarget.value : null,
          address: selectedOutStorage.address,
          city: selectedOutStorage.city,
          zipCode: selectedOutStorage.zipCode,
          phone: selectedOutStorage.phone,
          userId: user.userId,
          username: user.username,
          userEmail: user.email,
          outStorageMail: selectedOutStorage.email,
          compId: company === "cancun" ? 1 : 2,
          supplyDate: supplyDate,
          target: target,
          setToId: selectedTarget.key,
          setToName: selectedTarget.value,
          custCity: selectedTarget.custCity,
          custAddress: selectedTarget.custAddress,
          lines: [...parts],
        };
        const insertStatusData = await axios.post(
          `${fetchConf}/insertOrderFromOutStorage`,
          orderData
        );
        // console.log("insertStatusData: ", insertStatusData);
        if (await insertStatusData?.data) {
          setDialogHeader("הזמנה חדשה");
          setDialogContent("הזמנה לבית קירור נקלטה במערכת.");
          setShowDialog(true);
          setNeedToResetParts(true);
        }
      } catch (error) {
        console.log("Error inserting new out storage order:", error);
        setDialogHeader("שגיאה");
        setDialogContent("בעיה בקליטת הזמנה, אנא נסו שנית.");
        setShowDialog(true);
      }
    } else {
      setDialogHeader("שגיאה");
      setDialogContent(
        "פרטים חסרים בהזמנה, אנא וודאו כי לא חסר בית קירור, יעד וכל הפריטים מלאים כשורה."
      );
      setShowDialog(true);
    }
  };

  const handleTargetOptionsOpened = () => {
    setOpenTargets(true);
    if (targetOptions?.length === 0) {
      setDialogHeader("שגיאה");
      setDialogContent(
        "לא נמצאו יעדים אפשריים, נסו שנית לבחור עבור מי ההזמנה."
      );
      setShowDialog(true);
    }
  };

  if (showNewOutStorageOrder)
    return (
      <>
        <ScrollView
          vertical={true}
          style={{ backgroundColor: "#fff", minHeight: "100%" }}
        >
          <View
            component="main"
            maxWidth="xs"
            style={{ overflowX: "hidden", height: "100%" }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{ marginBottom: 16 }}
            >
              הזמנה מבית קירור{" "}
            </Typography>
            <View style={{ marginBottom: 16 }}>
              <SelectList
                setSelected={(item) => {
                  setSelectedOutStorage(
                    outStorageOptions?.filter((option) => {
                      return option.key === item;
                    })[0]
                  );
                }}
                save="key"
                data={outStorageOptions ?? []}
                placeholder="בחר/י בית קירור"
                searchPlaceholder="חיפוש"
              />
            </View>
            <View style={styles.rowComponent}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Typography variant="subtitle1">חברה:</Typography>
                <ButtonsSwitch
                  defaultValue={company}
                  setValue={setCompany}
                  options={[
                    {
                      value: "cancun",
                      content: "קנקון",
                    },
                    {
                      value: "eiv",
                      content: "איב",
                    },
                  ]}
                />
              </View>
              <View style={styles.verticalLine}></View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Typography variant="subtitle1">עבור:</Typography>
                <ButtonsSwitch
                  defaultValue={target}
                  setValue={setTarget}
                  options={[
                    {
                      value: "workers",
                      content: "מפעל",
                    },
                    {
                      value: "customers",
                      content: "לקוח",
                    },
                  ]}
                  setShowOverlayLoader={setShowOverlayLoader}
                />
              </View>
            </View>
            <View style={styles.rowComponent}>
              <Autocomplete
                sx={{ width: "48%" }}
                open={openTargets}
                onOpen={handleTargetOptionsOpened}
                onClose={() => {
                  setOpenTargets(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) => option.value}
                options={targetOptions}
                loading={switched}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="עבור"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {targetOptionsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.key}>
                      {option.value}
                    </li>
                  );
                }}
                onChange={(event, newValue) => setSelectedTarget(newValue)}
                key={resetAutoComplete}
              />
              <DatePicker
                label={"תאריך אספקה"}
                value={supplyDate}
                setValue={setSupplyDate}
                minDate={supplyDate}
                style={{ width: "48%" }}
              />
            </View>

            <View style={styles.horizontalLine} />
            <Typography variant="h6">פריטים:</Typography>
            <OutStorageOrderParts
              isMainOrderValid={isMainOrderValid}
              needToResetParts={needToResetParts}
              setNeedToResetParts={setNeedToResetParts}
              setShowDialog={setShowDialog}
              setDialogContent={setDialogContent}
              setDialogHeader={setDialogHeader}
              compId={company === "cancun" ? 1 : 2}
              outStorageId={selectedOutStorage?.key}
              handleSend={handleSend}
              setShowOverlayLoader={setShowOverlayLoader}
            />
            <View style={{ alignItems: "center" }}>
              <BackToNavBtn
                setShowCurr={setShowNewOutStorageOrder}
                setShowButtons={setShowButtons}
              />
            </View>
            <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
              <DialogTitle>{dialogHeader}</DialogTitle>
              <DialogContent>
                <DialogContentText>{dialogContent}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowDialog(false);
                  }}
                >
                  סגור
                </Button>
              </DialogActions>
            </Dialog>
          </View>
        </ScrollView>
      </>
    );
  else return null;
}

const styles = StyleSheet.create({
  rowComponent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  verticalLine: {
    height: 36,
    width: 1,
    backgroundColor: "#ccc",
  },
  horizontalLine: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    marginBottom: 16,
  },
});
