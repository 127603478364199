import { View, Text } from "react-native";
import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

export default function ButtonsSwitch({
  defaultValue,
  setValue,
  options,
  setShowOverlayLoader,
}) {
  const handleSwitch = (event, value) => {
    if (value !== null) {
      // if (setShowOverlayLoader) setShowOverlayLoader(true);
      setValue(value);
    }
  };

  const toggleButtons = options?.map((option) => {
    return (
      <ToggleButton key={option.value} value={option.value}>
        {option.content}
      </ToggleButton>
    );
  });
  return (
    <View>
      <ToggleButtonGroup
        value={defaultValue}
        onChange={handleSwitch}
        exclusive
        color="primary"
      >
        {toggleButtons}
      </ToggleButtonGroup>
    </View>
  );
}
