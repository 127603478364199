import { View, Text } from "react-native";
import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuBtn: { width: "50%", maxWidth: 150, marginBottom: 25 },
}));

export default function MenuButton({
  //   id,
  setShowSubMenu,
  setShowButtons,
  children,
}) {
  const classes = useStyles();

  return (
    <Button
      //   key={id}
      color="primary"
      variant="contained"
      className={classes.menuBtn}
      onClick={() => {
        if (setShowButtons && setShowSubMenu) {
          setShowButtons(false);
          setShowSubMenu(true);
        }
      }}
    >
      {children}
    </Button>
  );
}
