import { View, Text } from "react-native";
import React from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
export default function DatePicker({
  label,
  value,
  setValue,
  minDate,
  maxDate,
  style,
}) {
  return (
    <View style={style}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDatePicker
          label={label}
          inputFormat="DD/MM/YYYY"
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date) => {
            setValue(date._d);
          }}
          closeOnSelect={true}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </View>
  );
}
