import {
  Logo,
  TableConetent,
  NewAgentsOrder,
  PendingOrders,
  OutStorageScan,
  NewOutStorageOrder,
  AllOpenOutStoragesOrders,
  OutStoragesInventory,
  MenuButton,
  Deficiencies,
} from "../../components/index";
import { OrderPage } from "../../Pages";

import { View, Text, StyleSheet, ScrollView } from "react-native";
import React, { useState, useEffect } from "react";
import {
  Container,
  CssBaseline,
  Typography,
  TextField,
  makeStyles,
  Box,
  Button,
} from "@material-ui/core";

import { faBullseye } from "@fortawesome/free-solid-svg-icons";

import fetchConf from "../../fetchConfig";
import Collection from "../Collection/Collection";
const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatar: {
    margin: theme.spacing(0),
    // backgroundColor: theme.palette.secondary.main,
  },
  menuBtn: { width: "50%", marginBottom: 25 },
}));

export default function Nav({
  setShowOverlayLoader,
  showLoaderOverlay,
  user,
  Modal,
  signOut,
}) {
  const [showButtons, setShowButtons] = useState(true); // needed to be true
  const [showOrderPage, setshowOrderPage] = useState(false);
  const [showNewAgentsOrder, setShowNewAgentsOrder] = useState(false);
  const [showPendingOrders, setShowPendingOrders] = useState(false);
  const [showSearchOrder, setShowSearchOrder] = useState(false);
  const [showOutStorageScan, setShowOutStorageScan] = useState(false); // needed to be false
  const [showNewOutStorageOrder, setShowNewOutStorageOrder] = useState(false);
  const [showAllOutStoragesOrders, setShowAllOutStoragesOrders] =
    useState(false);
  const [showOutStoragesInventory, setShowOutStoragesInventory] =
    useState(false);
  const [showDeficiencies, setShowDeficiencies] = useState(false);
  const [showCollection, setShowCollection] = useState(false);

  const [eivInCancun, setEivInCancun] = useState(false);
  const [buttonsId, setButtonsId] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    if (user && showButtons) {
      setShowOverlayLoader(true);
      (async () => {
        try {
          const buttonsData = await axios.get(`${fetchConf}/getUserButtons`, {
            params: {
              typeId: user?.typeId,
            },
            timeout: 5000,
          });
          const resolvedButtons = await buttonsData.data;
          // console.log("resolvedButtons: ", resolvedButtons);
          setButtonsId(resolvedButtons?.map((button) => button.ButtonID));
        } catch (error) {
          console.log("Error: ", error);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
    // console.log("NAV useEffect");
    // if (user?.typeId === 100)
    //     console.log("נהג");
    // if (user?.typeId === 101)
    //     console.log("סוכן");
  }, [user]);

  useEffect(() => {
    if (!showNewOutStorageOrder) setEivInCancun(false);
    if (!showNewAgentsOrder) setEivInCancun(false);
  }, [showNewOutStorageOrder, showNewAgentsOrder]);

  // console.log(buttonsId);
  const userButtons = buttonsId?.map((id) => {
    switch (id) {
      case 1:
        return (
          <MenuButton
            key={id}
            setShowButtons={setShowButtons}
            setShowSubMenu={setshowOrderPage}
          >
            חיפוש תעודת משלוח
          </MenuButton>
        );
      case 2:
        return (
          <MenuButton
            key={id}
            setShowButtons={setShowButtons}
            setShowSubMenu={setShowNewAgentsOrder}
          >
            הזמנה חדשה
          </MenuButton>
        );
      case 3:
        return (
          <MenuButton
            key={id}
            setShowButtons={setShowButtons}
            setShowSubMenu={setShowPendingOrders}
          >
            הזמנות ממתינות
          </MenuButton>
        );
      case 4:
        return (
          <MenuButton
            key={id}
            // setShowButtons={setShowButtons}
            // setShowSubMenu={}
          >
            קבלות {user?.typeId === 100 ? "נהגים" : "סוכנים"}
          </MenuButton>
        );
      case 5:
        return (
          <MenuButton
            key={id}
            setShowButtons={setShowButtons}
            setShowSubMenu={setShowNewOutStorageOrder}
          >
            הזמנה מבית קירור
          </MenuButton>
        );
      case 6:
        return (
          <MenuButton
            key={id}
            setShowButtons={setShowButtons}
            setShowSubMenu={setShowOutStorageScan}
          >
            סריקה מבית קירור
          </MenuButton>
        );
      case 7:
        return (
          <MenuButton
            key={id}
            setShowButtons={setShowButtons}
            setShowSubMenu={setShowAllOutStoragesOrders}
          >
            פריטים פתוחים
          </MenuButton>
        );
      case 8:
        return (
          <MenuButton
            key={id}
            setShowButtons={setShowButtons}
            setShowSubMenu={setShowOutStoragesInventory}
          >
            מלאי בתי קירור
          </MenuButton>
        );
      case 9:
        return (
          <MenuButton
            key={id}
            setShowButtons={setShowButtons}
            setShowSubMenu={setShowDeficiencies}
          >
            חוסרים
          </MenuButton>
        );
      case 10:
        return (
          <MenuButton
            key={10}
            setShowButtons={setShowButtons}
            setShowSubMenu={setShowCollection}
          >
            ליקוט
          </MenuButton>
        );
    }
  });
  // if (userButtons?.length)
  //   userButtons.push(
  //     <MenuButton
  //       key={10}
  //       setShowButtons={setShowButtons}
  //       setShowSubMenu={setShowCollection}
  //     >
  //       ליקוט
  //     </MenuButton>
  //   );

  return (
    <ScrollView
      vertical={true}
      style={{ backgroundColor: "#fff", minHeight: "100%" }}
    >
      <Container
        component="main"
        // maxWidth="xs"
        style={{ overflowX: "hidden", height: "100%" }}
      >
        <CssBaseline />
        <div className={classes.paper}>
          <Box
            sx={{
              marginBottom: "16px",
              marginTop: "16px",
              textAlign: "center",
            }}
          >
            <div className={classes.avatar}>
              <Logo eivInCancun={eivInCancun} />
            </div>
          </Box>
          {showButtons && (
            <>
              <View style={styles.menuComponent}>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ marginBottom: 25 }}
                >
                  {`שלום ${user?.username}`}
                </Typography>
                {buttonsId?.length ? (
                  userButtons
                ) : (
                  <Text>לא נמצאו פעולות מתאימות למשתמש זה...</Text>
                )}
              </View>
              <View style={{ alignItems: "center", width: "95%" }}>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ width: "50%", maxWidth: 150 }}
                  onClick={signOut}
                >
                  התנתקות
                </Button>
              </View>
            </>
          )}
          <View
            style={[
              styles.subMenu,
              { display: showOrderPage ? "block" : "none" },
            ]}
          >
            <OrderPage
              showOrderPage={showOrderPage}
              setshowOrderPage={setshowOrderPage}
              setShowButtons={setShowButtons}
              Table={TableConetent}
              style={{ overflowX: "hidden" }}
              Modal={Modal}
              setShowOverlayLoader={setShowOverlayLoader}
              key={showOrderPage}
              // setShowButtons={setShowButtons}
            ></OrderPage>
          </View>
          <View
            style={[
              styles.subMenu,
              { display: showNewAgentsOrder ? "block" : "none" },
            ]}
          >
            <NewAgentsOrder
              setShowButtons={setShowButtons}
              setShowNewAgentsOrder={setShowNewAgentsOrder}
              showNewAgentsOrder={showNewAgentsOrder}
              user={user}
              Modal={Modal}
              setShowOverlayLoader={setShowOverlayLoader}
              setEivInCancun={setEivInCancun}
              key={showNewAgentsOrder}
            ></NewAgentsOrder>
          </View>
          <View
            style={[
              styles.subMenu,
              { display: showPendingOrders ? "block" : "none" },
            ]}
          >
            <PendingOrders
              setShowButtons={setShowButtons}
              setShowPendingOrders={setShowPendingOrders}
              user={user}
              showPendingOrders={showPendingOrders}
              setShowOverlayLoader={setShowOverlayLoader}
            ></PendingOrders>
          </View>
          <View
            style={[
              styles.subMenu,
              { display: showOutStorageScan ? "block" : "none" },
            ]}
          >
            <OutStorageScan
              setShowButtons={setShowButtons}
              setShowOutStorageScan={setShowOutStorageScan}
              showOutStorageScan={showOutStorageScan}
              setShowOverlayLoader={setShowOverlayLoader}
              user={user}
              Modal={Modal}
              key={showOutStorageScan}
            />
          </View>
          <View
            style={[
              styles.subMenu,
              { display: showNewOutStorageOrder ? "block" : "none" },
            ]}
          >
            <NewOutStorageOrder
              setShowButtons={setShowButtons}
              setShowNewOutStorageOrder={setShowNewOutStorageOrder}
              showNewOutStorageOrder={showNewOutStorageOrder}
              setShowOverlayLoader={setShowOverlayLoader}
              showLoaderOverlay={showLoaderOverlay}
              user={user}
              setEivInCancun={setEivInCancun}
              key={showNewOutStorageOrder}
            />
          </View>
          <View
            style={[
              styles.subMenu,
              { display: showAllOutStoragesOrders ? "block" : "none" },
            ]}
          >
            <AllOpenOutStoragesOrders
              setShowButtons={setShowButtons}
              setShowAllOutStoragesOrders={setShowAllOutStoragesOrders}
              showAllOutStoragesOrders={showAllOutStoragesOrders}
              setShowOverlayLoader={setShowOverlayLoader}
              key={showAllOutStoragesOrders}
            />
          </View>
          <View
            style={[
              styles.subMenu,
              { display: showOutStoragesInventory ? "block" : "none" },
            ]}
          >
            <OutStoragesInventory
              Modal={Modal}
              setShowButtons={setShowButtons}
              setShowOutStoragesInventory={setShowOutStoragesInventory}
              showOutStoragesInventory={showOutStoragesInventory}
              setShowOverlayLoader={setShowOverlayLoader}
              setEivInCancun={setEivInCancun}
              key={showOutStoragesInventory}
            />
          </View>
          <View
            style={[
              styles.subMenu,
              { display: showDeficiencies ? "block" : "none" },
            ]}
          >
            <Deficiencies
              Modal={Modal}
              user={user}
              setShowButtons={setShowButtons}
              setShowDeficiencies={setShowDeficiencies}
              showDeficiencies={showDeficiencies}
              setShowOverlayLoader={setShowOverlayLoader}
              key={showDeficiencies}
            />
          </View>
          <View
            style={[
              styles.subMenu,
              { display: showCollection ? "block" : "none" },
            ]}
          >
            <Collection
              user={user}
              setShowButtons={setShowButtons}
              setShowCollection={setShowCollection}
              showCollection={showCollection}
              setShowOverlayLoader={setShowOverlayLoader}
              key={showCollection}
            />
          </View>
        </div>
      </Container>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  menuBtn: { width: "50%", marginBottom: 25 },
  menuComponent: {
    width: "95%",
    height: "100%",
    writingDirection: "rtl",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: "15px",
  },
  subMenu: { width: "95%", height: "100%", writingDirection: "rtl" },
});
