import cancunLogo from "../../assets/cancun-logo.jpeg";
import eivLogo from "../../assets/eiv-ntes-logo.jpeg";
import ramadanLogo from "../../assets/ramadan-logo.png";

export default function Logo({ parentWidth, eivInCancun }) {
  const pickLogo = (appOriginComp) => {
    if (window.location.href.includes("cancun")) {
      return cancunLogo;
    }
    if (window.location.href.includes("ramadan")) {
      return ramadanLogo;
    }
    if (window.location.href.includes("eiv")) {
      return eivLogo;
    }

    return cancunLogo;
  };

  // const { height } = Dimensions.get("window");
  const logoForApp = pickLogo();
  return (
    <div style={{ textAlign: "center" }}>
      <img
        style={{ maxWidth: !isNaN(parentWidth) ? parentWidth * 0.7 : "65%" }}
        src={eivInCancun ? eivLogo : logoForApp}
      />
    </div>
  );
}
