// Components
import {
  TableConetent,
  Modal,
  OverlayLoader,
  NewOrder,
  PendingOrders,
  Nav,
} from "./components/index";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Login } from "./Pages/index";

import { StyleSheet, Text, View, Dimensions } from "react-native";
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";

export default function App() {
  const [view, setView] = useState(true);
  const [showNav, setShowNav] = useState(false); // needed to be false
  const { height } = Dimensions.get("window");
  const [showLoaderOverlay, setShowLoaderOverlay] = useState(false);
  const [user, setUser] = useState(null);

  const storeAuthenticatedUser = async (user) => {
    try {
      const userData = { ...user, dueIn: new Date() };
      await AsyncStorage.setItem("@Auth", JSON.stringify(userData));
    } catch (error) {
      console.log("Error storing authenticated user: ", error);
    }
  };

  const getAuthenticatedUser = async () => {
    try {
      const userData = await AsyncStorage.getItem("@Auth");
      return userData ? JSON.parse(userData) : null;
    } catch (error) {
      console.log("Error fetching authenticated user data: ", error);
    }
  };

  const removeStoredUser = async () => {
    try {
      await AsyncStorage.removeItem("@Auth");
    } catch (error) {
      console.log("Error removing stored data of logged in user: ", error);
    }
  };

  const lastLoggedInDiff = (userData) => {
    const today = new Date();
    // console.log((today - new Date(userData.dueIn)) / (1000 * 3600 * 24));
    if (userData)
      return (today - new Date(userData.dueIn)) / (1000 * 3600 * 24);
    return 2;
  };

  useEffect(() => {
    (async () => {
      setShowLoaderOverlay(true);
      try {
        const userData = await getAuthenticatedUser();
        if (userData && lastLoggedInDiff(userData) <= 1) {
          setUser(userData);
          setView(false);
          setShowNav(true);
        } else {
          await removeStoredUser();
        }
      } catch (error) {
        console.log("Error checking if user already logged in: ", error);
      } finally {
        setShowLoaderOverlay(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (user) {
      (async () => {
        if (!(await getAuthenticatedUser())) {
          await storeAuthenticatedUser(user);
        }
      })();
    }
  }, [user]);

  const signOut = async () => {
    setShowNav(false);
    setView(true);
    await removeStoredUser();
    setUser(null);
  };

  //   authenticated: true,
  //   userId: 101,
  //   username: "אביבית",
  //   needKuser: true,
  //   localDef: {
  //     DoNotNeedKuse: 0,
  //     DefKuserID: null,
  //     DefKuserDes: null,
  //   },
  //   maxAllow: 0,
  //   minAllow: 0,
  //   typeId: 101,
  // }
  // temporary user for tests in Cancun

  return (
    <View style={styles.container}>
      <OverlayLoader showLoader={showLoaderOverlay} />

      <Login
        style={{
          writingDirection: "rtl",
        }}
        setView={setView}
        view={view}
        // setOrderPageFun={setshowOrderPage}
        setShowNavFun={setShowNav}
        Modal={Modal}
        setShowOverlayLoader={setShowLoaderOverlay}
        setUser={setUser}
      ></Login>
      <View
        style={{
          display: showNav ? "block" : "none",
          width: "95%",
          height: "100%",
          writingDirection: "rtl",
        }}
      >
        <Nav
          style={{ overflowX: "hidden" }}
          setShowOverlayLoader={setShowLoaderOverlay}
          showLoaderOverlay={showLoaderOverlay}
          user={user}
          Modal={Modal}
          signOut={signOut}
        ></Nav>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    writingDirection: "rtl",
    flex: 1,
    backgroundColor: "lightblue",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    writingDirection: "rtl",
  },
});
