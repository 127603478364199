let fetchConf = null;
// console.log(window.location.hostname);
switch (window.location.hostname) {
  case "localhost":
    fetchConf = "http://localhost:3000";
    break;
  case "omsnewbeginnings.web.app":
    fetchConf = "https://app-server.ngagezone.com";
    // fetchConf = "https://ramadanserver.azurewebsites.net";
    break;
  case "ramadan.ngagezone.com":
    fetchConf = "https://ramadan-server-app.azurewebsites.net";
    break;
  default:
    fetchConf = "https://app-server.ngagezone.com";
    break;
}
// console.log(fetchConf);
export default fetchConf;
