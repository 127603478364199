import { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  Image,
  useWindowDimensions,
} from "react-native";
import { Autocomplete, TextField } from "@mui/material";
import {
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@material-ui/core";

import { PartReport } from "../index";

export default function OrderPart({
  addValidPart,
  index,
  part,
  partsOptions,
  unitOptions,
  customer,
  setShowOverlayLoader,
  setShowModal,
  setModalContent,
}) {
  const [partObject, setPartObject] = useState(part);
  const [open, setOpen] = useState(false);
  const [showPartReport, setShowPartReport] = useState(false);
  const [errorText, setErrorText] = useState("");
  const { width } = useWindowDimensions();
  const windowWidth = width;
  const handleExitPartForm = () => {
    // setPartObject({});
    setOpen(false);
    setErrorText("");
  };
  const validateIntInput = (num) => {
    return num && !isNaN(num) && parseFloat(num) > 0;
  };
  const handleSavePartData = () => {
    if (
      validateIntInput(partObject.quantity) &&
      validateIntInput(partObject.agentPrice)
    ) {
      addValidPart(index, partObject);
      setOpen(false);
      setErrorText("");
    } else {
      setErrorText("*ערכים לא תקינים בכמות או במחיר.");
    }
  };

  const partRow = partObject ? (
    <View>
      <Text>{`מוצר: ${partObject.label}`}</Text>
      <Text>{`כמות: ${partObject.quantity ?? 0}`}</Text>
    </View>
  ) : (
    <Text>בחר/י מוצר</Text>
    // <Text>{part.index}</Text>
  );

  useEffect(() => {
    setPartObject(part);
  }, [part]);

  // console.log(partObject);
  return (
    <View style={styles.part}>
      <View
        onClick={() => {
          setOpen(true);
        }}
        style={{ flex: 1 }}
      >
        {partRow}
      </View>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setErrorText("");
        }}
        style={{ overflowX: "hidden" }}
      >
        <DialogTitle>הוספת פריט להזמנה</DialogTitle>
        <DialogContent
          style={{
            width: windowWidth * 0.8,
            maxWidth: 400,
          }}
        >
          <View>
            <Autocomplete
              options={partsOptions}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.key}>
                    {option.label}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="בחר/י מוצר" />
              )}
              onChange={(event, newSelectedPart) => {
                setPartObject(newSelectedPart);
              }}
              value={partObject}
            />
            {partObject && (
              <View>
                <Text style={styles.partName}>{partObject.label}</Text>
                <View style={{ alignItems: "center", marginBottom: 8 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setShowPartReport(true);
                    }}
                  >
                    דו"ח פריט
                  </Button>
                  <PartReport
                    showPartReport={showPartReport}
                    setShowPartReport={setShowPartReport}
                    partId={partObject.id}
                    customerId={customer?.key}
                    setShowOverlayLoader={setShowOverlayLoader}
                    setShowModal={setShowModal}
                    setModalContent={setModalContent}
                  />
                </View>
                <View style={styles.partScreenRow}>
                  <Text>
                    קוד פריט:
                    <Text style={{ fontWeight: "bold" }}> {partObject.id}</Text>
                  </Text>
                  {partObject.base64Img ? (
                    <Image
                      source={{ uri: partObject.base64Img }}
                      style={styles.partImg}
                    />
                  ) : (
                    <Text style={styles.partImg}>לא קיימת תמונה</Text>
                  )}
                </View>
                <View style={styles.partScreenRow}>
                  <Autocomplete
                    options={unitOptions}
                    renderInput={(params) => (
                      <TextField {...params} label="בחר/י יחידות" />
                    )}
                    onChange={(event, newSelectedUnit) => {
                      setPartObject((prev) => {
                        return { ...prev, unit: newSelectedUnit };
                      });
                    }}
                    value={partObject?.unit ?? null}
                    size="small"
                    sx={{ width: "55%" }}
                  />
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text>כמות: </Text>
                    <TextInput
                      onChangeText={(text) => {
                        setPartObject((prev) => {
                          return { ...prev, quantity: text };
                        });
                      }}
                      value={partObject?.quantity ?? "0"}
                      selectTextOnFocus={true}
                      style={styles.quantityInput}
                    />
                  </View>
                </View>
                <View style={{ alignItems: "center" }}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text>מחיר: </Text>
                    <TextInput
                      onChangeText={(text) => {
                        setPartObject((prev) => {
                          return { ...prev, agentPrice: text };
                        });
                      }}
                      value={partObject?.agentPrice ?? ""}
                      keyboardType="number-pad"
                      selectTextOnFocus={true}
                      style={styles.quantityInput}
                    />
                  </View>
                  <Text>*מחיר הבסיס עבור הלקוח הוא {partObject.basePrice}</Text>
                </View>
                <TextInput
                  onChangeText={(text) => {
                    setPartObject((prev) => {
                      return { ...prev, comments: text };
                    });
                  }}
                  value={partObject?.comments ? partObject.comments : ""}
                  style={styles.comments}
                  placeholder="הערות..."
                  multiline
                />
              </View>
            )}
          </View>
          {errorText && <Text style={{ color: "red" }}>{errorText}</Text>}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" onClick={handleExitPartForm}>
            סגור
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSavePartData}
          >
            שמור
          </Button>
        </DialogActions>
      </Dialog>
    </View>
  );
}

const styles = StyleSheet.create({
  part: {
    border: "1px solid grey",
    borderRadius: "5px",
    width: "75%",
    padding: 8,
  },
  partScreenRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  partName: {
    textAlign: "center",
    fontWeight: "bold",
    marginVertical: 8,
  },
  partImg: {
    width: 75,
    height: 75,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "grey",
    textAlign: "center",
  },
  quantityInput: {
    borderWidth: 1,
    padding: 6,
    borderRadius: 4,
    borderColor: "grey",
    width: 75,
    height: 40,
    textAlign: "right",
    // direction: "rtl",
  },
  comments: {
    //   flex: 1,
    borderWidth: 1,
    padding: 16,
    borderRadius: 4,
    writingDirection: "rtl",
    marginTop: 16,
    textAlign: "right",
  },
});
