import { View, ScrollView, StyleSheet } from "react-native";
import BackToNavBtn from "../BackToNavBtn/BackToNavBtn";
import { TextField, Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import StatusIcon from "../UI/StatusIcon";
import {
  faCheck,
  faBan,
  faXmark,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../Modal/Modal";
import axios from "axios";
import fetchConf from "../../fetchConfig";

export default function Collection({
  setShowCollection,
  setShowButtons,
  setShowOverlayLoader,
}) {
  const [modalContent, setModalContent] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [partBarcodeInput, setPartBarcodeInput] = useState("");
  const [isPartInputBlurred, setIsPartInputBlurred] = useState(false);
  const [isPartInputFocussed, setIsPartInputFocussed] = useState(false);
  const [isStickerInputFocussed, setIsStickerInputFocussed] = useState(false);
  const [stickerBarcodeInput, setStickerBarcodeInput] = useState("");
  const [inputsErrors, setInputsErrors] = useState({
    part: false,
    sticker: false,
  });
  const [switchedInputs, setSwitchedInputs] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isTypingSticker, setIsTypingSticker] = useState(false);
  const partInputRef = useRef(null);
  const stickerInputRef = useRef(null);
  const partInputTimeout = useRef(null);
  const stickerInputTimeout = useRef(null);

  console.log({
    partBarcodeInput,
    stickerBarcodeInput,
    isPartInputBlurred,
    isPartInputFocussed,
    isStickerInputFocussed,
    inputsErrors,
    switchedInputs,
    isTyping,
    isTypingSticker,
  });
  const onPartInputChange = (event) => {
    setInputsErrors({ part: false, sticker: false });
    const value = event.target.value;
    setPartBarcodeInput(value.trim());
    // Clear the previous timeout (if any)
    clearTimeout(partInputTimeout.current);
    // Set a new timeout for 2 seconds
    partInputTimeout.current = setTimeout(() => {
      setIsTyping(false);
    }, 2000);

    // User is currently typing
    setIsTyping(true);
  };

  useEffect(() => {
    if (
      partBarcodeInput.length === 13 &&
      !isPartInputBlurred &&
      !inputsErrors.part &&
      !switchedInputs
    ) {
      if (partInputRef?.current?.blur) partInputRef.current.blur();
    } else if (
      partBarcodeInput.length === 13 &&
      isPartInputBlurred &&
      !inputsErrors.part &&
      !switchedInputs
    ) {
      if (stickerInputRef?.current?.focus) {
        stickerInputRef.current.focus();
        setSwitchedInputs(true);
      }
    }
  }, [partBarcodeInput, inputsErrors, isPartInputBlurred]);

  const onStickerInputChange = (event) => {
    setInputsErrors({ part: false, sticker: false });
    const value = event.target.value;
    setStickerBarcodeInput(value.trim());
    // Clear the previous timeout (if any)
    clearTimeout(stickerInputTimeout.current);
    // Set a new timeout for 2 seconds
    stickerInputTimeout.current = setTimeout(() => {
      setIsTypingSticker(false);
    }, 2000);

    // User is currently typing
    setIsTypingSticker(true);
  };

  useEffect(() => {
    if (stickerBarcodeInput.length === 9 && partBarcodeInput.length === 13) {
      console.log("Checking and updating collection status...");
      setShowOverlayLoader(true);
      (async () => {
        try {
          const collectionRes = await axios.post(`${fetchConf}/collection`, {
            partBarcode: partBarcodeInput,
            stickerBarcode: stickerBarcodeInput,
          });
          if (collectionRes.data?.statusCode >= 0) {
            switch (collectionRes.data?.statusCode) {
              case 0:
                setModalContent({
                  title: "ליקוט בוצע בהצלחה",
                  content: <StatusIcon icon={faCheck} color="green" />,
                });
                setShowModal(true);
                break;

              case 1:
                setModalContent({
                  title: "מדבקה כבר נסרקה",
                  content: <StatusIcon icon={faBan} color="red" />,
                });
                setShowModal(true);
                break;
              case 2:
                setModalContent({
                  title: "אי התאמה בין מדבקה לפריט",
                  content: <StatusIcon icon={faXmark} color="red" />,
                });
                setShowModal(true);
                break;
              case 3:
                setModalContent({
                  title: "ליקוט בוצע, הפריט כבר לוקט במלואו במסגרת הזמנה זו",
                  content: <StatusIcon icon={faCheck} color="green" />,
                });
                setShowModal(true);
                break;
              case 4:
                setModalContent({
                  title: "ברקוד פריט לא קיים",
                  content: <StatusIcon icon={faBan} color="red" />,
                });
                setShowModal(true);
                break;
              case 5:
                setModalContent({
                  title: "ברקוד מדבקה לא קיים",
                  content: <StatusIcon icon={faBan} color="red" />,
                });
                setShowModal(true);
                break;
              case 6:
                setModalContent({
                  title: "ליקוט בוצע בהצלחה וההזמנה נקלטה במלואה",
                  content: <StatusIcon icon={faCheckDouble} color="green" />,
                });
                setShowModal(true);
                break;
            }
            setPartBarcodeInput("");
            setStickerBarcodeInput("");
            setInputsErrors({
              part: false,
              sticker: false,
            });
            setSwitchedInputs(false);
          }
        } catch (error) {
          setModalContent({
            title: "שגיאה",
            content: "שגיאה במהלך בדיקת ברקודים, אנא נסו שנית.",
          });
          setShowModal(true);
          console.log("Error fetching deficiencies data: ", error);
        } finally {
          setShowOverlayLoader(false);
        }
      })();
    }
  }, [stickerBarcodeInput]);

  const validatePartInput = () => {
    setIsPartInputBlurred(true);
    setIsPartInputFocussed(false);
    if (partBarcodeInput.trim().length !== 13) {
      setInputsErrors({
        part: true,
        sticker: false,
      });
    } else {
      setInputsErrors({
        part: false,
        sticker: false,
      });
    }
  };

  const validateStickerInput = () => {
    if (stickerBarcodeInput.trim().length !== 9) {
      setInputsErrors({
        part: false,
        sticker: true,
      });
    } else {
      setInputsErrors({
        part: false,
        sticker: false,
      });
    }
  };

  const handleModalBtnClicked = () => {
    partInputRef.current.focus();
  };

  const handlePartInputClicked = () => {
    if (partInputRef.current) partInputRef.current.select();
  };
  const handleStickerInputClicked = () => {
    if (stickerInputRef?.current?.select) stickerInputRef.current.select();
  };

  return (
    <ScrollView vertical={true} style={styles.rootComponent}>
      <View component="main" maxWidth="xs" style={styles.mainComponent}>
        <View style={styles.header}>
          <Typography component="h1" variant="h5">
            ליקוט
          </Typography>
        </View>
      </View>
      <View style={{ gap: 24 }}>
        <TextField
          size="small"
          label="ברקוד פריט"
          variant="outlined"
          onChange={onPartInputChange}
          autoFocus={!partBarcodeInput.length}
          error={
            inputsErrors.part ||
            (isPartInputFocussed &&
              !isTyping &&
              partBarcodeInput.length !== 13 &&
              partBarcodeInput.length > 0)
          }
          helperText={
            inputsErrors.part ||
            (isPartInputFocussed &&
              !isTyping &&
              partBarcodeInput.length !== 13 &&
              partBarcodeInput.length > 0)
              ? "ברקוד פריט לא תקין(13 ספרות)"
              : ""
          }
          onBlur={validatePartInput}
          onFocus={() => {
            setIsPartInputBlurred(false);
            setIsPartInputFocussed(true);
          }}
          value={partBarcodeInput}
          inputRef={partInputRef}
          onClick={handlePartInputClicked}
        />
        <TextField
          size="small"
          label="ברקוד מדבקה"
          variant="outlined"
          onChange={onStickerInputChange}
          disabled={inputsErrors.part || !partBarcodeInput.length}
          error={
            inputsErrors.sticker ||
            (isStickerInputFocussed &&
              !isTypingSticker &&
              stickerBarcodeInput.length !== 9 &&
              stickerBarcodeInput.length > 0)
          }
          helperText={
            inputsErrors.sticker ||
            (isStickerInputFocussed &&
              !isTypingSticker &&
              stickerBarcodeInput.length !== 9 &&
              stickerBarcodeInput.length > 0)
              ? "ברקוד מדבקה לא תקין(9 ספרות)"
              : ""
          }
          onBlur={validateStickerInput}
          value={stickerBarcodeInput}
          inputRef={stickerInputRef}
          onClick={handleStickerInputClicked}
          onFocus={() => setIsStickerInputFocussed(true)}
        />
      </View>
      <View style={styles.footerButtons}>
        <BackToNavBtn
          setShowCurr={setShowCollection}
          setShowButtons={setShowButtons}
        />
      </View>
      <Modal
        show={showModal}
        content={modalContent.content}
        title={modalContent.title}
        buttonText="אישור"
        toggleShowModal={setShowModal}
        buttonFunction={handleModalBtnClicked}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  rootComponent: {
    backgroundColor: "#fff",
    // minHeight: "100%",
    flex: 1,
  },
  mainComponent: {
    overflowX: "hidden",
    // height: ,
    flex: 1,
  },
  header: {
    marginBottom: 16,
  },
  footerButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 36,
  },
});
