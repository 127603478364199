import { View, Text, StyleSheet } from "react-native";
import React from "react";
import IconButton from "@mui/material/IconButton";
import { AddCircleOutlined, RemoveCircleOutline } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { OSPart } from "../index";
import { Button } from "@material-ui/core";

export default function OutStorageOrderParts({
  isMainOrderValid,
  needToResetParts,
  setNeedToResetParts,
  setShowDialog,
  setDialogContent,
  setDialogHeader,
  outStorageId,
  compId,
  handleSend,
  setShowOverlayLoader,
}) {
  const [parts, setParts] = useState([]);

  useEffect(() => {
    if (needToResetParts) {
      setParts([]);
      setNeedToResetParts(false);
    }
  }, [needToResetParts]);

  const handleAddPart = () => {
    if (isMainOrderValid) setParts((prev) => [...prev, { index: prev.length }]);
    else {
      setDialogHeader("שגיאה");
      setDialogContent(
        "יש לוודא כי נבחר בית קירור, תאריך אספקה, חברה ועבור מי ההזמנה."
      );
      setShowDialog(true);
    }
  };

  const handleRemovePart = (index) => {
    setParts((prev) => {
      const newPartsArray = [...prev];
      newPartsArray.splice(index, 1);
      return newPartsArray;
    });
  };

  const addValidPart = (index, partData) => {
    setParts((prev) => {
      const newPartsArray = [...prev];
      newPartsArray[index] = partData;
      return newPartsArray;
    });
  };

  //   console.log(parts);
  const partsRows = parts.map((part, index) => {
    return (
      <View style={styles.partComponent} key={index}>
        <OSPart
          outStorageId={outStorageId}
          compId={compId}
          setShowDialog={setShowDialog}
          setDialogContent={setDialogContent}
          setDialogHeader={setDialogHeader}
          addValidPart={addValidPart}
          index={index}
          setShowOverlayLoader={setShowOverlayLoader}
        />
        <IconButton onClick={() => handleRemovePart(index)}>
          <RemoveCircleOutline />
          <Text> הסר</Text>
        </IconButton>
      </View>
    );
  });

  return (
    <View>
      {partsRows}
      <IconButton onClick={handleAddPart}>
        <AddCircleOutlined />
        <Text> הוסף פריט נוסף</Text>
      </IconButton>
      <View style={{ alignItems: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleSend(parts);
          }}
          style={{ width: "75%" }}
        >
          שלח הזמנה
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  partComponent: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
